import React from "react";
import { AwsTrustPolicy } from "@prequel/react";
import { Alert, CopyToClipboard } from "@prequel-internal/react-components";

type IamTrustPolicyProps = {
  iamTrustPolicy?: AwsTrustPolicy;
  kind?: "FIRSTPARTYSOURCE" | "THIRDPARTYDESTINATION";
};
const IamTrustPolicy = ({
  iamTrustPolicy,
  kind = "THIRDPARTYDESTINATION",
}: IamTrustPolicyProps) => {
  return (
    <div>
      {iamTrustPolicy ? (
        <>
          <label className="block text-sm font-medium text-gray-700">
            Custom Trust Policy
          </label>
          <div className="w-full shadow-sm inline-flex rounded-md pl-3 pr-1 py-2 mt-1 text-gray-500 text-sm bg-gray-50 border border-gray-300 font-mono overflow-auto	resize-y ">
            <div className="w-full h-14 mr-3 whitespace-pre-wrap">
              {JSON.stringify(iamTrustPolicy, null, 2)}
            </div>
            <CopyToClipboard
              textToCopy={JSON.stringify(iamTrustPolicy, null, 2)}
              className="sticky top-0 self-start"
            />
          </div>
          <p className="mt-2 text-xs text-gray-400">
            {kind === "FIRSTPARTYSOURCE"
              ? "This is the Custom Trust Policy you will use to create a new IAM Role. "
              : "This is the Custom Trust Policy your customer will use to create a new IAM Role. "}
            <a
              href="https://docs.prequel.co/docs"
              target="_blank"
              rel="noreferrer"
              className="font-medium text-primary-600 hover:text-primary-500"
            >
              See documentation for details.
            </a>
          </p>
        </>
      ) : (
        <Alert>
          Error: the service account has not been configured, but it is required
          for role-based authentication.
        </Alert>
      )}
    </div>
  );
};

export default IamTrustPolicy;
