import { ExistingDestination, Transfer } from "@prequel/react";
import axios, {
  ApiRoutes,
  ApiResponse,
  AppError,
  ErrorResponse,
  ID_PLACEHOLDER,
} from "../../axios";

import { WithRedirect } from "..";

const getTransfers: (
  destinationId: ExistingDestination["id"] | undefined
) => Promise<{
  destinationId: ExistingDestination["id"] | undefined;
  transfers: Transfer[];
}> = (destinationId) => {
  return axios
    .get(`${ApiRoutes.DESTINATIONS}/${destinationId}${ApiRoutes.TRANSFERS}`, {
      params: { count: 20 },
    })
    .then((response: ApiResponse<{ transfers: Transfer[] }>) => ({
      destinationId,
      transfers: response.data.data.transfers,
    }))
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message ||
            "Failed to fetch transfers for destination.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postTransfer: (p: {
  destinationId: ExistingDestination["id"];
  fullRefresh: boolean;
  models?: string[];
}) => Promise<{ destinationId: ExistingDestination["id"] }> = ({
  destinationId,
  fullRefresh,
  models,
}) => {
  return axios
    .post(`${ApiRoutes.DESTINATIONS}/${destinationId}${ApiRoutes.TRANSFER}`, {
      full_refresh: fullRefresh,
      models: models,
    })
    .then((response: ApiResponse<void>) => ({
      destinationId,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Transfer failed to enqueue.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const cancelExportTransfer: ({
  transferId,
  redirect,
}: WithRedirect<{ transferId: Transfer["id"] }>) => Promise<WithRedirect<{}>> =
  ({ transferId, redirect }) => {
    const url = ApiRoutes.CANCEL_EXPORT_TRANSFER.replace(
      ID_PLACEHOLDER,
      transferId
    );
    return axios
      .post(url)
      .then((response: ApiResponse<{}>) => ({
        redirect,
      }))
      .catch((reason: ErrorResponse) => {
        const e: AppError = {
          error: {
            message:
              reason.response?.data?.message || "Transfer failed to enqueue.",
            suppressGlobalNotification: false,
            statusCode: reason.response?.status,
          },
        };
        throw e;
      });
  };

const TransfersService = {
  cancelExportTransfer,
  getTransfers,
  postTransfer,
};
export default TransfersService;
