import React from "react";
import { useParams } from "react-router-dom";

import TestSourceConnection from "../TestSourceConnection";
import { useTypedSelector } from "../../../store";
import { selectSource } from "../../../store/sources/sources.duck";

const SourceConnectionDetails = () => {
  const { connectionId } = useParams<{ connectionId: string }>();
  const source = useTypedSelector((state) => selectSource(state, connectionId));

  if (!source) {
    return <div>The source could not be found.</div>;
  }
  return <TestSourceConnection existingSource={source} />;
};

export default SourceConnectionDetails;
