import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonStyle,
  CardGrid,
  DisplayCard,
} from "@prequel-internal/react-components";
import { ReactComponent as ShieldIcon } from "../../../assets/icons/shield.svg";

import Timestamp from "../../../components/Timestamp";
import VendorLogo from "../../../components/VendorLogo";
import { getReadableVendor, getVendorLogoUrl } from "../../../lib";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchSourceVendors,
  fetchSources,
  selectSourceVendors,
  selectSources,
} from "../../../store/sources/sources.duck";
import ExistingSource from "../../../store/sources";

const SourcesCards = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const connections = useTypedSelector(selectSources);
  const sourceVendors = useTypedSelector(selectSourceVendors);

  useEffect(() => {
    dispatch(fetchSources());
    dispatch(fetchSourceVendors());
  }, [dispatch]);

  // Map to EnumObject shape while we still use old source vendors form shape
  const vendors = sourceVendors?.map(
    ({ display_name, vendor_name, logo_url }) => ({
      key: vendor_name,
      display: display_name,
      icon_url: logo_url,
    })
  );
  const onCardClick = (id: string) => navigate(id);

  if (connections?.length === 0) {
    return (
      <div className="flex-col bg-gray-50 border border-gray-200 text-gray-500 rounded p-4 text-sm">
        <div className="flex">
          <ShieldIcon
            className="h-12 w-12 text-emerald-600"
            aria-hidden="true"
          />
          <div className="ml-3">
            <p className="font-medium text-gray-900 mb-1">
              No configured source.
            </p>
            <p>{"Click 'Add source' to add your first source."}</p>
          </div>
        </div>
        <div className="mt-3 ml-9">
          <Button
            text="Add Source"
            onClick={() => navigate("new")}
            type={ButtonStyle.TERTIARY}
          />
        </div>
      </div>
    );
  }

  return (
    <CardGrid>
      {connections?.map(
        ({
          id,
          vendor,
          name,
          host,
          bucket_name,
          created_at,
        }: ExistingSource) => (
          <DisplayCard key={id} onClick={() => onCardClick(id)}>
            <div className="flex items-center gap-x-4 border-b border-slate-900/5 bg-slate-50 group-hover:bg-slate-100 p-6">
              <VendorLogo
                logo_url={getVendorLogoUrl(vendor, vendors ?? [])}
                size="md"
              />
              <div className="text-sm font-medium leading-6 text-slate-900">
                <div>{name}</div>
                {(host || bucket_name) && (
                  <div className="font-mono text-slate-400">
                    {host ?? bucket_name}
                  </div>
                )}
              </div>
            </div>
            <dl className="-my-3 divide-y divide-slate-100 px-6 py-4 text-sm leading-6">
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-slate-500">Vendor</dt>
                <dd className="flex items-start gap-x-2">
                  <div className="font-medium text-slate-700">
                    {getReadableVendor(vendor, vendors ?? [])}
                  </div>
                </dd>
              </div>
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-slate-500">Created</dt>
                <dd className="text-slate-700">
                  <Timestamp timestamp={created_at} />
                </dd>
              </div>
            </dl>
          </DisplayCard>
        )
      )}
    </CardGrid>
  );
};

export default SourcesCards;
