import ExistingRecipient, {
  ExistingSourceCredential,
  Recipient,
  SourceCredential,
} from ".";
import { WithRedirect } from "..";
import axios, {
  ApiRoutes,
  ApiResponse,
  ErrorResponse,
  AppError,
  RequestStatus,
} from "../../axios";

const getRecipients: () => Promise<ExistingRecipient[]> = () => {
  return axios
    .get(ApiRoutes.RECIPIENTS)
    .then(
      (response: ApiResponse<{ recipients: ExistingRecipient[] }>) =>
        response.data.data.recipients
    )
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message || "Failed to fetch recipients.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const getSourceCredentials: () => Promise<ExistingSourceCredential[]> = () => {
  return axios
    .get(ApiRoutes.RECIPIENT_SOURCE_CREDENTIALS)
    .then(
      (
        response: ApiResponse<{
          recipient_source_credentials: ExistingSourceCredential[];
        }>
      ) => response.data.data.recipient_source_credentials
    )
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message ||
            "Failed to fetch recipient source credentials.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const testSourceCredentials: (c: SourceCredential) => Promise<RequestStatus> = (
  credential
) => {
  return axios
    .post(ApiRoutes.TEST_RECIPIENT_SOURCE_CREDENTIALS, {
      recipient_source_credential: credential,
    })
    .then((response: ApiResponse<RequestStatus>) => response.data.data)
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Source connection test failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postRecipient: ({
  recipient,
  redirect,
}: WithRedirect<{
  recipient: Recipient;
}>) => Promise<WithRedirect<{ recipient: ExistingRecipient }>> = ({
  recipient,
  redirect,
}) => {
  return axios
    .post(ApiRoutes.RECIPIENTS, { recipient })
    .then((response: ApiResponse<{ recipient: ExistingRecipient }>) => ({
      recipient: response.data.data.recipient,
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message || "Failed to post recipient.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postSourceCredential: ({
  sourceCredential,
  redirect,
}: WithRedirect<{ sourceCredential: SourceCredential }>) => Promise<
  WithRedirect<{ sourceCredential: ExistingSourceCredential }>
> = ({ sourceCredential, redirect }) => {
  return axios
    .post(ApiRoutes.RECIPIENT_SOURCE_CREDENTIALS, {
      recipient_source_Credential: sourceCredential,
    })
    .then(
      (
        response: ApiResponse<{
          recipient_source_credential: ExistingSourceCredential;
        }>
      ) => ({
        sourceCredential: response.data.data.recipient_source_credential,
        redirect,
      })
    )
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message ||
            "Failed to post source credential.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const RecipientsService = {
  getRecipients,
  getSourceCredentials,
  postRecipient,
  postSourceCredential,
  testSourceCredentials,
};
export default RecipientsService;
