import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, takeEvery } from "redux-saga/effects";
import { createWorkerSaga, RootState } from "..";
import { AppError } from "../../axios";
import DocsService from "./docs.service";

type DocsState = {
  createDestination:
    | undefined
    | {
        content: string | undefined;
        vendor: string | undefined;
        title: string | undefined;
      };
};

const initialState: DocsState = {
  createDestination: undefined,
};

const fetchCreateDestinationDocsReducer: CaseReducer<
  DocsState,
  PayloadAction<{ vendor: string }>
> = (state, action) => {
  state.createDestination = {
    vendor: action.payload.vendor,
    content: undefined,
    title: undefined,
  };
};

const fetchCreateDestinationDocsSuccessReducer: CaseReducer<
  DocsState,
  PayloadAction<{ content: string; title: string }>
> = (state, action) => {
  state.createDestination = {
    vendor: state.createDestination?.vendor,
    content: action.payload.content,
    title: action.payload.title,
  };
};

const fetchCreateDestinationDocsFailureReducer: CaseReducer<
  DocsState,
  PayloadAction<AppError>
> = (state) => state;

function* watchFetchCreateDestinationDocs() {
  yield takeEvery(
    fetchCreateDestinationDocs.type,
    createWorkerSaga(
      fetchCreateDestinationDocs,
      fetchCreateDestinationDocsSuccess,
      fetchCreateDestinationDocsFailure,
      DocsService.getCreateDestinationDocs
    )
  );
}

const docsSlice = createSlice({
  name: "docs",
  initialState,
  reducers: {
    fetchCreateDestinationDocs: fetchCreateDestinationDocsReducer,
    fetchCreateDestinationDocsSuccess: fetchCreateDestinationDocsSuccessReducer,
    fetchCreateDestinationDocsFailure: fetchCreateDestinationDocsFailureReducer,
  },
});

export const {
  fetchCreateDestinationDocs,
  fetchCreateDestinationDocsSuccess,
  fetchCreateDestinationDocsFailure,
} = docsSlice.actions;

export const selectCreateDestinationDocs = ({ docs }: RootState) =>
  docs.createDestination;

export function* docsSaga() {
  yield all([watchFetchCreateDestinationDocs()]);
}

export default docsSlice.reducer;
