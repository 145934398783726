import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  BadgeColor,
  Button,
  ButtonStyle,
  MenuButton,
  MenuItemStyle,
  Table,
  TableRow,
  TableCellType,
  Spinner,
} from "@prequel-internal/react-components";

import { ReactComponent as RotateIcon } from "../../../assets/icons/rotate-cw.svg";
import Timestamp from "../../../components/Timestamp";
import { getDuration } from "../../../store/transfers";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchTransfers,
  selectIsLoadingTransfers,
  selectTransfers,
  cancelExportTransfer,
  selectIsCancelingTransfer,
} from "../../../store/transfers/transfers.duck";
import { ReactComponent as XIcon } from "../../../assets/icons/x.svg";

const TransfersList = () => {
  const { connectionId } = useParams<{ connectionId: string }>();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const isLoadingTransfers = useTypedSelector(selectIsLoadingTransfers);
  const transfers = useTypedSelector(selectTransfers(connectionId));
  const isCancelingTransfer = useTypedSelector(selectIsCancelingTransfer);

  const [rows, setRows] = useState<TableRow[]>([]);

  const getCancelTransferMenuItem = (transferId: string, status: string) => ({
    label: status === "RUNNING" ? "Cancel running transfer" : "Cancel transfer",
    style: status === "RUNNING" ? MenuItemStyle.DANGER : MenuItemStyle.PRIMARY,
    onClick: () =>
      dispatch(
        cancelExportTransfer({
          transferId: transferId,
          redirect: () => {
            navigate(0);
          },
        })
      ),
  });

  useEffect(() => {
    getTransfers(connectionId);
  }, [dispatch, connectionId]);

  const getTransfers = (connectionId: string | undefined) => {
    if (connectionId) {
      dispatch(fetchTransfers(connectionId));
    }
  };

  useEffect(() => {
    const transferRows = transfers?.map((transfer) => ({
      id: transfer.id,
      cells: [
        {
          value: <Timestamp timestamp={transfer.submitted_at} />,
        },
        {
          value: (
            <Badge
              color={
                transfer.status === "ERROR"
                  ? BadgeColor.RED
                  : transfer.status === "PENDING"
                  ? BadgeColor.ORANGE
                  : transfer.status === "CANCELLED"
                  ? BadgeColor.GRAY
                  : transfer.status === "RUNNING"
                  ? BadgeColor.BLUE
                  : transfer.status === "SUCCESS"
                  ? BadgeColor.GREEN
                  : transfer.status === "ORPHANED"
                  ? BadgeColor.GRAY
                  : BadgeColor.GRAY // Default or unknown status can also be gray.
              }
              text={transfer.status ?? "UNKNOWN"}
            />
          ),
        },
        {
          value: (
            <div className="flex text-xs max-w-sm">
              <div className="whitespace-pre-line	">
                {transfer.rows_transferred.toLocaleString()}
              </div>
            </div>
          ),
        },
        {
          value: (
            <div className="flex text-xs max-w-sm">
              <div className="whitespace-pre-line	">{getDuration(transfer)}</div>
            </div>
          ),
        },
        {
          value: (
            <div className="flex text-xs">
              <div className="whitespace-pre-line">{transfer.log}</div>
            </div>
          ),
        },
        {
          type: TableCellType.BUTTON,
          value: (transfer.status === "PENDING" ||
            transfer.status === "RUNNING") && (
            <div className="flex text-xs m-auto">
              <MenuButton
                type={ButtonStyle.TERTIARY}
                disabled={isCancelingTransfer}
                spin={true}
                menuItems={[
                  getCancelTransferMenuItem(transfer.id, transfer.status),
                ]}
                icon={
                  <XIcon
                    className="h-4 w-3 mt-1 mb-1 text-red-600"
                    aria-hidden="true"
                  />
                }
              />
            </div>
          ),
        },
      ],
    }));
    transferRows && setRows(transferRows);
  }, [transfers]);

  const columns = [
    "Submitted At",
    "Status",
    "Rows Transferred",
    "Time Elapsed",
    "Details",
    "", // Empty column for action buttons
  ];

  return (
    <Table
      header={
        connectionId && (
          <div className="flex justify-end">
            <Button
              text={
                isLoadingTransfers ? (
                  <div className="flex">
                    <Spinner.Inline />
                  </div>
                ) : (
                  <RotateIcon className="h-5 w-5" />
                )
              }
              onClick={() => getTransfers(connectionId)}
              type={ButtonStyle.TERTIARY}
            />
          </div>
        )
      }
      columns={columns}
      rows={rows}
      footer={`${rows.length} most recent transfers`}
    />
  );
};

export default TransfersList;
