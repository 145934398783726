import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  PreparedDestination,
  Destination,
  useDestinationForm,
  useDestination,
  prepareDestinationWithForm,
  prepareDestinationFromExisting,
  getDefaultAuthMethod,
  getDefaultBucketVendor,
  getDefaultMetastore,
  computeChangedFields,
} from "@prequel/react";
import {
  Button,
  ButtonStyle,
  Spinner,
} from "@prequel-internal/react-components";
import Markdown from "react-markdown";

import { Navigate, useNavigate, useParams } from "react-router-dom";

import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  createDestination,
  fetchDestinations,
  resetDestinationRequest,
  selectDestination,
  selectDestinationRequest,
  selectDestinationTest,
  updateDestination,
} from "../../../store/destinations/destinations.duck";
import {
  fetchCreateDestinationDocs,
  selectCreateDestinationDocs,
} from "../../../store/docs/docs.duck";
import {
  fetchRecipients,
  selectRecipients,
} from "../../../store/recipients/recipients.duck";
import WrapperSelector from "./WrapperSelector";
import AdvancedOptionsSection from "./AdvancedOptionsSection";
import ProductsAndModels from "./ProductsAndModels";
import TestDestinationConnection from "../TestDestinationConnection";
import DocumentationSidebar from "./DocumentationSidebar";
import ConfirmDataDestinationModal from "../../ConfirmDataDestinationModal";

import { ReactComponent as DocumentTextIcon } from "../../../assets/icons/document-text.svg";
import { fetchOrg, selectOrg } from "../../../store/org/org.duck";
import { env } from "../../../env";
import { extractDataDestination } from "../../../store/destinations";

const DestinationForm = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const { connectionId } = useParams<{ connectionId: string }>();
  const [destination, setDestination] = useDestination();

  const destinationToEdit = useTypedSelector((state) =>
    selectDestination(state, connectionId)
  );
  const isEditing = !!destinationToEdit; // Evaluates to true if destinationToEdit exists, otherwise evaluates to false
  const destinationTest = useTypedSelector(selectDestinationTest);
  const destinationRequest = useTypedSelector(selectDestinationRequest);
  const recipients = useTypedSelector(selectRecipients);
  const org = useTypedSelector(selectOrg);
  const createDestinationDocs = useTypedSelector(selectCreateDestinationDocs);

  const form = useDestinationForm(destination, org?.id ?? "", {
    includeInternalFields: true,
    host: env.REACT_APP_API_SERVER,
  });
  // Flag for whether or not we have populated the destination from destinationToEdit
  // Once we set up the initial values, this is set to true so we stop re-populating the destination
  const [destinationPopulated, setDestinationPopulated] = useState(false);
  const [docsOpen, setDocsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modelsError, setModelsError] = useState<string>();

  const setDestinationField = useCallback(
    (
      key: keyof Destination,
      value: string | string[] | boolean | number | undefined
    ) => {
      setDestination((currentDestination) => ({
        ...currentDestination,
        [key]: value,
      }));
    },
    [setDestination]
  );

  useEffect(() => {
    dispatch(fetchRecipients());
    dispatch(fetchDestinations());
    dispatch(fetchOrg());
  }, [dispatch]);

  useEffect(() => {
    if (form && destinationToEdit && !destinationPopulated) {
      const converted = prepareDestinationFromExisting(destinationToEdit);
      setDestination({ ...converted });
      setDestinationPopulated(true);
    }
  }, [form, destinationToEdit]);

  // Cleanup test state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetDestinationRequest());
    };
  }, []);

  const selectedRecipient = useMemo(
    () => recipients?.find(({ id }) => id === destination.recipient_id),
    [destination.recipient_id]
  );

  useEffect(() => {
    if (selectedRecipient) {
      // When we select a recipient for the destination, set the products accordingly
      setDestinationField("products", selectedRecipient.products);
    }
  }, [selectedRecipient]);

  const selectedVendor = useMemo(() => {
    if (form) {
      const { fields } = form[0];
      if (fields[0].form_element === "select") {
        return fields[0].enum?.find(({ key }) => key === destination.vendor);
      }
    }
  }, [form, destination.vendor]);

  useEffect(() => {
    if (selectedVendor && !isEditing) {
      // dispatch selected vendor docs on create
      dispatch(
        fetchCreateDestinationDocs({ vendor: selectedVendor.key.toString() })
      );

      // only set defaults when creating new destination
      const defaultAuthMethod = getDefaultAuthMethod(
        selectedVendor.key.toString()
      );
      const defaultBucketVendor = getDefaultBucketVendor(
        selectedVendor.key.toString()
      );
      const defaultMetastore = getDefaultMetastore(
        selectedVendor.key.toString()
      );
      setDestination((currentDestination) => ({
        ...currentDestination,
        auth_method: defaultAuthMethod,
        bucket_vendor: defaultBucketVendor,
        metastore: defaultMetastore,
      }));
    } else if (destination.vendor) {
      // dispatch existing vendor docs on edit
      dispatch(
        fetchCreateDestinationDocs({ vendor: destination.vendor.toString() })
      );
    }
  }, [selectedVendor]);

  const selectedVendorDocs = useMemo(() => {
    if (createDestinationDocs) {
      return createDestinationDocs;
    }
  }, [createDestinationDocs]);

  const validateForm = () =>
    formRef.current ? formRef.current.reportValidity() : false;

  const preparedDestination: PreparedDestination = useMemo(() => {
    const p = prepareDestinationWithForm(destination, form);
    p.products =
      destination.id_in_provider_system && !isEditing
        ? destination.products
        : undefined;
    return p;
  }, [destination, form, isEditing]);

  // Handle destination submission
  const onSave = () => {
    if (destination.enabled_models?.length === 0) {
      setModelsError("This is a required field");
      return;
    }

    if (isEditing) {
      const changed = computeChangedFields(
        destinationToEdit,
        preparedDestination
      );
      dispatch(
        updateDestination({
          destinationId: destinationToEdit.id,
          fields: changed,
          redirect: () => navigate("/export/destinations"),
        })
      );
    } else {
      setShowModal(true);
    }
  };

  const onConfirm = () => {
    setShowModal(false);
    dispatch(
      createDestination({
        destination: preparedDestination,
        redirect: () => navigate("/export/destinations"),
      })
    );
  };

  // Intercept native form submission, prevent default, and run test
  // We use the default form submission event so that we can borrow the browsers built-in support for handling missing required fields
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSave();
  };

  if (!form || (connectionId && destinationToEdit === undefined)) {
    return <Spinner />;
  }

  if (connectionId && destinationToEdit === null) {
    return <Navigate to="/export/destinations" replace />;
  }

  return (
    <div className="relative h-full">
      <div className="h-full">
        <div className="flex flex-col max-w-lg pb-16">
          {selectedRecipient && (
            <ConfirmDataDestinationModal
              kind="ADD_DESTINATION"
              show={showModal}
              setShow={setShowModal}
              dataIdentifier={selectedRecipient.name}
              dataDestination={extractDataDestination(preparedDestination)}
              onConfirm={onConfirm}
            />
          )}
          <form className="space-y-8" onSubmit={onSubmit} ref={formRef}>
            {form.map((section) => {
              // Advanced options section is expandable
              if (section.id === 6) {
                return (
                  <Fragment key={section.id}>
                    <div className="space-y-4">
                      <ProductsAndModels
                        destination={destination}
                        setDestinationField={setDestinationField}
                        modelsError={modelsError}
                        disabled={destinationTest?.status === "processing"}
                        isEditing={isEditing}
                      />
                    </div>
                    <div>
                      <AdvancedOptionsSection
                        fields={section.fields}
                        destination={destination}
                        setDestinationField={setDestinationField}
                        disabled={destinationTest?.status === "processing"}
                        isEditing={isEditing}
                      />
                    </div>
                  </Fragment>
                );
              }

              return (
                <Fragment key={section.id}>
                  <div key={section.id}>
                    {section.id !== 1 && (
                      <>
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {section.title}
                        </label>
                        {section.subtitle ? (
                          <div className="mt-1 mb-4">
                            <p className="mt-1 text-sm text-gray-500">
                              {section.subtitle}
                              {section.id === 3 && selectedVendor && (
                                <>
                                  {" For assistance, "}
                                  <a
                                    href={selectedVendor.docs}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="font-medium text-primary-600 hover:text-primary-500"
                                  >
                                    view our documentation on{" "}
                                    {selectedVendor.display}.
                                  </a>
                                </>
                              )}
                            </p>
                          </div>
                        ) : (
                          <br />
                        )}
                      </>
                    )}
                    <div className="space-y-4">
                      {section.fields.map((field) => (
                        <div key={field.name}>
                          <WrapperSelector
                            field={field}
                            destination={destination}
                            setDestinationField={setDestinationField}
                            disabled={destinationTest?.status === "processing"}
                            isEditing={isEditing}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="w-full h-px bg-gray-200" /> {/* Divider  */}
                </Fragment>
              );
            })}
            <TestDestinationConnection
              beforeSubmitTest={validateForm}
              preparedDestination={preparedDestination}
              existingDestination={destinationToEdit}
            />
          </form>
          <div className="flex justify-end mt-8">
            {isEditing && (
              <Button
                className="mr-3"
                type={ButtonStyle.TERTIARY}
                onClick={() => navigate(-1)}
                text="Cancel"
              />
            )}
            <Button
              type={
                !(destinationTest.status === "success")
                  ? ButtonStyle.TERTIARY
                  : ButtonStyle.PRIMARY
              }
              disabled={!(destinationTest.status === "success")}
              onClick={onSave}
              text={
                destinationRequest.status === "processing" ? (
                  <div className="flex">
                    <Spinner.Inline className="text-white" />
                    Saving Destination...
                  </div>
                ) : (
                  "Save Destination"
                )
              }
            />
          </div>
        </div>
      </div>
      {selectedVendorDocs?.content && (
        <>
          <Button
            text="Documentation"
            type={ButtonStyle.TERTIARY}
            icon={<DocumentTextIcon className="h-full w-full" />}
            className={`fixed top-4 right-4 text-white p-2 rounded transition-opacity duration-300 ${
              docsOpen ? "opacity-0 pointer-events-none" : "opacity-100"
            }`}
            onClick={() => setDocsOpen(true)}
          />
          <div
            className={`fixed top-0 right-0 h-full border-l overflow-y-auto transition-transform duration-300 ease-in-out transform ${
              docsOpen ? "translate-x-0" : "translate-x-full"
            } md:w-1/3`}
          >
            <DocumentationSidebar
              markdown={selectedVendorDocs?.content}
              title={selectedVendorDocs?.title || "Setup Guide"}
              onClose={() => setDocsOpen(false)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DestinationForm;
