import React from "react";
import {
  parseServiceAccountKey,
  ServiceAccountKey,
  ModelConfigColumn,
} from "@prequel/react";
import { RequestStatus } from "../../axios";

// Using this enum for the type values of the source interfaces
// allows us easier type detection and to create discriminated unions
// for us in multi-purpose components like SourceDetails
export enum SourceType {
  Source,
  PreparedSource,
  ExistingSource,
}

export interface Source {
  type: SourceType.Source;
  name: string;
  vendor: string;
  use_ssh_tunnel: boolean;
  host?: string;
  port?: string;
  database?: string;
  username?: string;
  password?: string;
  bucket_name?: string;
  bucket_region?: string;
  bucket_vendor?: string;
  aws_iam_role?: string;
  gcp_iam_role?: string;
  bucket_secret_key?: string;
  bucket_access_id?: string;
  service_account_key?: string;
  disable_ssl?: boolean;
  ssh_public_key?: string;
  ssh_tunnel_host?: string;
  ssh_tunnel_port?: string;
  ssh_tunnel_username?: string;
  wallet?: string;
}

export interface PreparedSource {
  type: SourceType.PreparedSource;
  name: string;
  vendor: string;
  use_ssh_tunnel: boolean;
  host?: string;
  port?: number;
  database?: string;
  username?: string;
  password?: string;
  bucket_name?: string;
  bucket_region?: string;
  bucket_vendor?: string;
  aws_iam_role?: string;
  gcp_iam_role?: string;
  bucket_secret_key?: string;
  bucket_access_id?: string;
  service_account_key?: ServiceAccountKey;
  ssh_public_key?: string;
  ssh_tunnel_host?: string;
  ssh_tunnel_port?: number;
  ssh_tunnel_username?: string;
  wallet?: string;
}

export default interface ExistingSource extends Omit<Source, "type"> {
  type: SourceType.ExistingSource;
  id: string;
  created_at: string;
  updated_at: string;
}

export type SourceTest = {
  status: RequestStatus;
  message?: string;
};

export type ModelMapping = {
  model_name: string;
  source_table_name: string;
  description: string;
  mappings: ModelConfigColumn[];
};

export type ExistingSourceTestPayload = {
  sourceId: ExistingSource["id"];
  fields?: Partial<PreparedSource>;
};

export const prepareSource: (s: Source) => PreparedSource = (source) => {
  const serviceAccountKey = parseServiceAccountKey(source.service_account_key);

  const prepared: PreparedSource = {
    ...source,
    type: SourceType.PreparedSource,
    service_account_key: serviceAccountKey,
    port: parseInt(source.port || ""),
    ssh_tunnel_port: parseInt(source.ssh_tunnel_port || ""),
    wallet: source.disable_ssl ? "" : source.wallet,
  };

  return prepared;
};

export const convertToSource: (e: ExistingSource) => Source = (existing) => ({
  ...existing,
  type: SourceType.Source,
  password: "",
  service_account_key: JSON.stringify(existing.service_account_key) ?? "",
  port: existing.port ? existing.port.toString() : "",
  ssh_tunnel_port: existing.ssh_tunnel_port
    ? existing.ssh_tunnel_port.toString()
    : "",
  use_ssh_tunnel: existing.use_ssh_tunnel ?? false,
});

export const computeChangedFields: (
  e: ExistingSource,
  s: PreparedSource
) => Partial<PreparedSource> = (existingSource, preparedSource) => {
  const changedFields: Partial<PreparedSource> = {};
  for (const key in existingSource) {
    if (
      existingSource[key as keyof ExistingSource] !==
      preparedSource[key as keyof PreparedSource]
    ) {
      Object.assign(changedFields, {
        [key]: preparedSource[key as keyof PreparedSource],
      });
    }
  }

  // Special case password because ExistingDestination does not have the password populated
  if (preparedSource.password && preparedSource.password !== "") {
    changedFields.password = preparedSource.password;
  }

  // Special case service_account_key because ExistingDestination does not have service_account_key populated
  if (preparedSource.service_account_key) {
    changedFields.service_account_key = preparedSource.service_account_key;
  }

  return changedFields;
};
