import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import { Element } from "hast";
import remarkGfm from "remark-gfm";
import DownloadMenu from "./DownloadMenu";
import { ReactComponent as XIcon } from "../../../../assets/icons/x.svg";
import { ReactComponent as CheckCircleIcon } from "../../../../assets/icons/check-circle.svg";
import {
  CopyToClipboard,
  SectionExpandable,
} from "@prequel-internal/react-components";

import { useTypedDispatch } from "../../../../store";
import { openLightbox } from "../../../../store/lightbox/lightbox.duck";

type DocumentationSidebarProps = {
  markdown: string;
  title: string;
  onClose: () => void;
};
const DocumentationSidebar = ({
  markdown,
  title,
  onClose,
}: DocumentationSidebarProps) => {
  const dispatch = useTypedDispatch();

  // Split markdown into sections based on "## " (h2) headers
  const markdownSectionSeparator = /(?=\n## )/;
  const sections = markdown
    .split(markdownSectionSeparator)
    .map((section, index) => {
      const trimmedSection = section.trim();
      const firstLineEnd = trimmedSection.indexOf("\n");

      const titleStartIndex = 2; // The length of "## "
      const titleEndIndex = firstLineEnd;
      const title = trimmedSection
        .substring(titleStartIndex, titleEndIndex)
        .trim();

      const contentStartIndex = firstLineEnd + 1;
      const content = trimmedSection.substring(contentStartIndex).trim();
      return { title, content, index };
    });

  const MarkdownTaskListItem = ({
    children,
  }: {
    children?: React.ReactNode;
  }) => {
    return (
      <li className="flex items-start pb-2">
        <CheckCircleIcon className="h-4 w-4 text-emerald-600 mr-3 mt-2 flex-shrink-0" />
        <span>{children}</span>
      </li>
    );
  };

  type MarkdownBlockProps = {
    node: Element | undefined;
    children?: React.ReactNode;
  };
  const MarkdownCodeBlock = ({ node, ...props }: MarkdownBlockProps) => {
    // this is unfortunate; react-markdown returns nested children, in this case the code block string will always be nested 1 object deep
    const textToCopy = useMemo(() => {
      return React.Children.toArray(props.children)
        .map((child: any) => child?.props?.children || "")
        .join("");
    }, [props.children]);

    return (
      <div className="w-full not-prose shadow-sm inline-flex rounded-md pl-3 pr-1 py-2 mt-1 text-gray-500 text-sm bg-gray-50 border border-gray-300 font-mono overflow-auto resize-y">
        <pre className="w-full max-h-48 mr-3 whitespace-pre-wrap" {...props} />
        <CopyToClipboard
          textToCopy={textToCopy}
          className="sticky top-0 self-start"
        />
      </div>
    );
  };

  // Custom implementation of readme callouts: https://docs.readme.com/rdmd/docs/callouts
  const MarkdownCalloutBlockquote = ({
    node,
    ...props
  }: MarkdownBlockProps) => {
    const getStyleFromFirstEmoji = (node) => {
      if (!node || !node.children) {
        return "";
      }

      for (const child of node.children) {
        const calloutStyle =
          "prose-p:p-0 prose-p:m-0 my-4 p-2 flex-row space-y-2 rounded-sm";
        if (child.value) {
          if (child.value.startsWith("❗️")) {
            return `bg-red-100 border-l-4 border-red-500 ${calloutStyle}`;
          }
          if (child.value.startsWith("📘")) {
            return `bg-blue-100 border-l-4 border-blue-500 ${calloutStyle}`;
          }
          if (child.value.startsWith("👍")) {
            return `bg-green-100 border-l-4 border-green-500 ${calloutStyle}`;
          }
          if (child.value.startsWith("🚧")) {
            return `bg-yellow-100 border-l-4 border-yellow-500 ${calloutStyle}`;
          }
        }
        const nestedEmoji = getStyleFromFirstEmoji(child);
        if (nestedEmoji) {
          return nestedEmoji;
        }
      }

      return "bg-gray-100 border-l-4 border-gray-500";
    };

    const blockquoteStyle = useMemo(() => getStyleFromFirstEmoji(node), [node]);

    return (
      <blockquote
        className={`not-italic font-normal text-slate-500 prose-p:before:content-none prose-p:after:content-none ${blockquoteStyle}`}
        {...props}
      />
    );
  };

  type MarkdownLightboxImageProps = {
    node: Element | undefined;
    src?: string;
    alt?: string;
  };
  const MarkdownLightboxImage = ({
    node,
    ...props
  }: MarkdownLightboxImageProps) => {
    return (
      <img
        {...props}
        className="cursor-pointer"
        onClick={() =>
          dispatch(openLightbox({ src: props.src || "", alt: props.alt || "" }))
        }
      />
    );
  };

  const markdownFilename = title.toLowerCase().split(" ").join("-") + ".md";

  const downloadMarkdown = () => {
    const element = document.createElement("a");
    const file = new Blob([markdown], { type: "text/markdown" });
    element.href = URL.createObjectURL(file);
    element.download = markdownFilename;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <div className="h-full w-full space-y-1.5 bg-neutral-50 prose-slate prose prose-sm max-w-none">
      <div className="flex justify-between items-center px-4 py-3 border-b">
        <span className="flex flex-col">
          <span className="text-lg font-semibold text-gray-900 capitalize">
            {title}
          </span>
        </span>
        <span className="flex items-center justify-between">
          <DownloadMenu
            menuItems={[{ text: "Download .md", onClick: downloadMarkdown }]}
          />
          <button
            onClick={onClose}
            className="ml-1 p-1 text-gray-700 rounded-md hover:bg-slate-100 focus:outline-none"
          >
            <XIcon className="h-5 w-5 stroke-1.5" aria-hidden="true" />
          </button>
        </span>
      </div>
      <div className="overflow-visible flex flex-col pl-2 pr-2  z-10">
        {sections.map((section) => (
          <span key={section.index} className="mb-2">
            <SectionExpandable
              label={section.title}
              key={section.index}
              transparent={true}
              defaultOpen={true}
            >
              <ReactMarkdown
                className={
                  "prose-code:rounded-md prose-code:bg-gray-100 prose-code:p-1 prose-code:before:hidden prose-code:after:hidden"
                }
                unwrapDisallowed={true}
                // add support for github flavored markdown (tasklists and callouts)
                remarkPlugins={[remarkGfm]}
                disallowedElements={["input", "ul", "ol"]}
                components={{
                  h1: ({ node, ...props }) => <p {...props} />,
                  h2: ({ node, ...props }) => <p {...props} />,
                  h3: ({ node, ...props }) => <h4 {...props} />, // h3 looks too big
                  blockquote: ({ node, ...props }) => (
                    <MarkdownCalloutBlockquote node={node} {...props} />
                  ),
                  li: ({ node, className, ...props }) => {
                    if (className?.includes("task-list-item")) {
                      return <MarkdownTaskListItem {...props} />;
                    }
                    return <li className="list-none" {...props} />;
                  },
                  pre: ({ node, ...props }) => (
                    <MarkdownCodeBlock node={node} {...props} />
                  ),
                  img: ({ node, ...props }) => (
                    <MarkdownLightboxImage node={node} {...props} />
                  ),
                }}
              >
                {section.content}
              </ReactMarkdown>
            </SectionExpandable>
          </span>
        ))}
      </div>
    </div>
  );
};

export default DocumentationSidebar;
