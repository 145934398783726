import React, { useState, useEffect } from "react";

import {
  CopyToClipboard,
  StatusLight,
  Status,
  Table,
  TableRow,
} from "@prequel-internal/react-components";

import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchMagicLinks,
  selectMagicLinks,
} from "../../../store/magic_links/magic_links.duck";
import { getTimeRemaining } from "../../../store/magic_links";
import { sortByCreatedAt } from "../../../lib";

const MagicLinksTable = () => {
  const [rows, setRows] = useState<TableRow[]>([]);
  const dispatch = useTypedDispatch();
  const links = useTypedSelector(selectMagicLinks);

  useEffect(() => {
    dispatch(fetchMagicLinks());
  }, [dispatch]);

  useEffect(() => {
    if (links) {
      // copy the array before sorting
      const sortedLinks = [...links].sort(sortByCreatedAt);
      const linkRows = sortedLinks.map((link) => ({
        id: link.id,
        cells: [
          { value: link.name },
          { value: link.id_in_provider_system },
          {
            value: (
              <div className="flex items-center max-w-xs">
                <CopyToClipboard textToCopy={link.link} />
                <span className="truncate">{link.link}</span>
              </div>
            ),
          },
          {
            value: (
              <div className="flex items-center text-gray-500">
                <StatusLight
                  status={link.has_been_used ? Status.INACTIVE : Status.ACTIVE}
                />
                {link.has_been_used ? "Submitted" : "Active"}
              </div>
            ),
          },
          { value: getTimeRemaining(link.created_at) },
          {
            value: (
              <div className="flex items-center max-w-xs">
                <span className="truncate">{link.host}</span>
              </div>
            ),
          },
        ],
      }));
      setRows(linkRows);
    }
  }, [links]);

  const columns = [
    "Name",
    "Id In Provider System",
    "Magic Link",
    "Status",
    "Time Remaining",
    "Host",
  ];

  return (
    <Table
      columns={columns}
      rows={rows}
      footer={`${rows.length} magic links`}
    />
  );
};

export default MagicLinksTable;
