import React from "react";
import { IamRole as IamRoleType } from "@prequel/react";
import { Alert, CopyToClipboard } from "@prequel-internal/react-components";

export enum IamRoleKind {
  FirstParty,
  ThirdParty,
}
type IamRoleProps = {
  role?: IamRoleType;
  kind?: IamRoleKind;
};
const IamRole = ({ role, kind = IamRoleKind.ThirdParty }: IamRoleProps) => {
  return (
    <div>
      {role ? (
        <>
          <label className="block text-sm font-medium text-gray-700">
            {kind === IamRoleKind.FirstParty
              ? "Prequel Service Account Email"
              : "Provider Service Account Email"}
          </label>
          <div className="w-full shadow-sm inline-flex rounded-md pl-3 pr-1 py-2 mt-1 text-gray-500 text-sm bg-gray-50 border border-gray-300 font-mono">
            <div className="w-full truncate mr-3">{role.id}</div>
            <CopyToClipboard textToCopy={role.id} />
          </div>
          <p className="mt-2 text-xs text-gray-400">
            {kind === IamRoleKind.FirstParty
              ? "This is the Prequel Service Account that will assume the Service Account that you generate. "
              : "This is the Service Account that will assume the Service Account that your customer generates. "}
            <a
              href="https://docs.prequel.co/docs"
              target="_blank"
              rel="noreferrer"
              className="font-medium text-primary-600 hover:text-primary-500"
            >
              See documentation for details.
            </a>
          </p>
        </>
      ) : (
        <Alert>
          Error: the service account has not been configured, but it is required
          for role-based authentication.
        </Alert>
      )}
    </div>
  );
};

export default IamRole;
