import React from "react";
import { Toast } from "@prequel-internal/react-components";
import { useTypedSelector, useTypedDispatch } from "../../store";
import { dismissToast, selectToasts } from "../../store/toasts/toasts.duck";

const ToastsFeature = () => {
  const toasts = useTypedSelector(selectToasts);

  const dispatch = useTypedDispatch();

  const dismissToastHandler = (toastId: string) => {
    dispatch(dismissToast({ toastId: toastId }));
  };

  if (!toasts || toasts.length === 0) {
    return null;
  } else {
    return (
      <div className="fixed p-5 space-y-1 z-20 w-1/3 bottom-0 right-0 overflow-y-auto">
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            toast={toast}
            dismissHandler={dismissToastHandler}
          />
        ))}
      </div>
    );
  }
};

export default ToastsFeature;
