import React from "react";
import ReactDOMClient from "react-dom/client";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

import "./index.css"; // Import index.css before the AppWrapper so the base styling in index.css doesn't overwrite the component styling
import AppWrapper from "./App";

// Find root element, or throw error if not found.
const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Failed to find the root element");
}

// Create a root.
const root = ReactDOMClient.createRoot(rootElement);
root.render(
  <Provider store={store}>
    <AppWrapper />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
