import axios, { ApiRoutes, ApiResponse, ErrorResponse } from "../../axios";

import { Organization } from ".";

const getOrg: () => Promise<Organization> = () => {
  return axios
    .get(ApiRoutes.ORGS)
    .then((response: ApiResponse<{ organization: Organization }>) => {
      return response.data.data.organization;
    })
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message ||
            "Failed to fetch organization details.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const OrgService = {
  getOrg,
};
export default OrgService;
