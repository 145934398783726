import axios, {
  ApiResponse,
  ApiRoutes,
  AppError,
  ErrorResponse,
} from "../../axios";

const getPublicKey: () => Promise<string> = () => {
  return axios
    .post(ApiRoutes.GENERATE_PUBLIC_KEY)
    .then(
      (response: ApiResponse<{ public_key: string }>) =>
        response.data.data.public_key
    )
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message ||
            "Failed to generate public SSH key.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const SSHService = {
  getPublicKey,
};

export default SSHService;
