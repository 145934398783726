import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, put, takeEvery } from "redux-saga/effects";
import ExistingProduct, { Product } from ".";

import ProductsService from "./products.service";
import {
  createWorkerSaga,
  createRedirectSaga,
  RootState,
  WithRedirect,
} from "..";
import { AppError } from "../../axios";
import { createToast } from "../toasts/toasts.duck";

type ProductsState = {
  products: ExistingProduct[] | undefined;
};
const initialState: ProductsState = {
  products: undefined,
};

const createProductReducer: CaseReducer<
  ProductsState,
  PayloadAction<WithRedirect<{ product: Product }>>
> = (state: ProductsState) => state;

const createProductSuccessReducer: CaseReducer<
  ProductsState,
  PayloadAction<WithRedirect<{ product: Product }>>
> = (state: ProductsState) => state;

const createProductFailureReducer: CaseReducer<
  ProductsState,
  PayloadAction<AppError>
> = (state: ProductsState) => state;

const fetchProductsReducer: CaseReducer<ProductsState, PayloadAction<void>> = (
  state: ProductsState
) => state;

const fetchProductsSuccessReducer: CaseReducer<
  ProductsState,
  PayloadAction<ExistingProduct[]>
> = (state: ProductsState, action: PayloadAction<ExistingProduct[]>) => {
  // Sort products by name
  const sortedProducts = action.payload.sort((a, b) =>
    a.product_name.localeCompare(b.product_name)
  );

  // Sort models list for each product
  sortedProducts.forEach((product) => {
    product.models.sort((a, b) => a.localeCompare(b));
  });

  state.products = sortedProducts;
};

const fetchProductsFailureReducer: CaseReducer<
  ProductsState,
  PayloadAction<AppError>
> = (state: ProductsState) => state;

function* watchCreateProduct() {
  yield takeEvery(
    createProduct.type,
    createWorkerSaga(
      createProduct,
      createProductSuccess,
      createProductFailure,
      ProductsService.postProduct
    )
  );
}

function* watchCreateProductSuccess() {
  yield takeEvery(createProductSuccess.type, function* () {
    yield put(fetchProducts());
    yield put(
      createToast({
        toast: {
          id: new Date().getTime().toString(),
          kind: "SUCCESS",
          message: "Product created successfully!",
        },
      })
    );
  });
  yield takeEvery(createProduct.type, createRedirectSaga());
}

function* watchFetchProducts() {
  yield takeEvery(
    fetchProducts.type,
    createWorkerSaga(
      fetchProducts,
      fetchProductsSuccess,
      fetchProductsFailure,
      ProductsService.getProducts
    )
  );
}

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    createProduct: createProductReducer,
    createProductSuccess: createProductSuccessReducer,
    createProductFailure: createProductFailureReducer,
    fetchProducts: fetchProductsReducer,
    fetchProductsSuccess: fetchProductsSuccessReducer,
    fetchProductsFailure: fetchProductsFailureReducer,
  },
});

export const {
  createProduct,
  createProductSuccess,
  createProductFailure,
  fetchProducts,
  fetchProductsSuccess,
  fetchProductsFailure,
} = productSlice.actions;

export const selectProducts = ({ products }: RootState) => products.products;

export function* productsSaga() {
  yield all([
    watchCreateProduct(),
    watchCreateProductSuccess(),
    watchFetchProducts(),
  ]);
}
export default productSlice.reducer;
