import { Transfer } from "@prequel/react";
import { differenceInHours, differenceInMinutes, formatISO } from "date-fns";

export const getDuration: (t: Transfer) => string = (transfer) => {
  if (transfer.started_at === undefined) {
    // Return early because there is no time to compute
    return "-";
  }

  const endedAt = transfer.ended_at ?? formatISO(new Date());
  const h = Math.max(
    differenceInHours(new Date(endedAt), new Date(transfer.started_at)),
    0
  );
  // This gets the total difference in minutes so we % 60 to get the minutes over an hour
  const m = Math.max(
    differenceInMinutes(new Date(endedAt), new Date(transfer.started_at)) % 60,
    0
  );

  if (h === 0 && m === 0) {
    return "<1m";
  }

  let result = "";
  if (h > 0) {
    result = `${h}h `;
  }

  result += `${m}m`;
  return result;
};
