import { parseJSON } from "date-fns";
import { AwsTrustPolicy, EnumObject, IamRole } from "@prequel/react";
import { DropdownListItem } from "@prequel-internal/react-components";

export type VendorField = {
  name: string;
  is_required: boolean;
  label: string;
  placeholder: string;
  help: string;
};

export type SourceVendor = MagicLinkVendor;
export type MagicLinkForm = MagicLinkVendor[];
export type MagicLinkVendor = {
  display_name: string;
  vendor_name: string;
  logo_url: string;
  fields: VendorField[];
  docs: string;
  uses_service_account: boolean;
  uses_staging_bucket: boolean;
  supports_ssh_tunnel: boolean;
  role?: IamRole;
  aws_trust_policy?: AwsTrustPolicy;
};

export type Connection = {
  id: string;
  vendor: string;
  name: string;
  host?: string;
  bucket_name?: string;
  created_at: string;
};

export type DropdownListItemWithFields = DropdownListItem<string> & {
  fields: VendorField[];
  docs: string;
  uses_staging_bucket: boolean;
  uses_service_account: boolean;
  supports_ssh_tunnel: boolean;
  role?: IamRole;
  aws_trust_policy?: AwsTrustPolicy;
};

export type SSHTunnelProperties = {
  use_ssh_tunnel: boolean;
  ssh_public_key?: string;
  ssh_tunnel_host?: string;
  ssh_tunnel_port?: string;
  ssh_tunnel_username?: string;
};

export const getReadableVendor = (
  vendor: string,
  destinationVendors: EnumObject[]
) => {
  const destinationVendor = destinationVendors.find(
    ({ key }) => key === vendor
  );
  return destinationVendor?.display ?? "";
};

// In the unlikely case that the backend returns a destination type that is not supported in the frontend, type the function to return undefined
export const getVendorLogoUrl = (
  vendor: string,
  destinationVendors: EnumObject[]
) => {
  const destinationVendor = destinationVendors.find(
    ({ key }) => key === vendor
  );
  return destinationVendor?.icon_url ?? "";
};

export const sortByCreatedAt = (
  a: { created_at: string },
  b: { created_at: string }
) => {
  const nullDate = new Date(0).toISOString(); // Create nullDate to replace null values with an early timestamp (epoch time)
  const dateA = parseJSON(a.created_at ?? nullDate);
  const dateB = parseJSON(b.created_at ?? nullDate);
  if (dateA > dateB) {
    return -1;
  }

  if (dateB > dateA) {
    return 1;
  }

  return 0;
};
