import { Environment } from ".";
import { WithRedirect } from "..";
import axios, {
  ApiRoutes,
  ApiResponse,
  AppError,
  ErrorResponse,
} from "../../axios";

const postSSOEnabled: (e: string) => Promise<boolean> = (email) => {
  return axios
    .post(ApiRoutes.PUBLIC_SSO_ENABLED, { email })
    .then(
      (response: ApiResponse<{ sso_enabled: boolean }>) =>
        response.data.data.sso_enabled
    )
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to fetch SSO enabled.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postSSOLogin: (email: string) => Promise<void> = (email) => {
  return axios
    .post(ApiRoutes.PUBLIC_SSO_LOGIN, { email })
    .then((response: ApiResponse<{ url: string }>) => {
      window.location.href = response.data.data.url;
    })
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message ||
            "Failed to fetch SSO login redirect.",
          suppressGlobalNotification: false,
          statusCode: reason?.response?.status,
        },
      };
      throw e;
    });
};

const postLoginToken: (code: string) => Promise<void> = (code) => {
  return axios
    .post(ApiRoutes.PUBLIC_RETRIEVE_TOKEN, { code })
    .then((response: ApiResponse<void>) => {
      // return nothing
    })
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Failed to fetch login token.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postPasswordlessLogin: (email: string) => Promise<void> = (email) => {
  return axios
    .post(ApiRoutes.PUBLIC_PASSWORDLESS_LOGIN, { email })
    .then((response: ApiResponse<void>) => {
      // return nothing
    })
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message ||
            "Failed to send passwordless login.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postGoogleOauthLogin: () => Promise<void> = () => {
  return axios
    .post(ApiRoutes.PUBLIC_GOOGLE_OAUTH_LOGIN)
    .then((response: ApiResponse<{ url: string }>) => {
      window.location.href = response.data.data.url;
    })
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message ||
            "Failed to send Google OAuth login.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const getAuthStatus: () => Promise<{ environment: Environment }> = () => {
  return axios
    .get(ApiRoutes.AUTH_STATUS)
    .then((response: ApiResponse<{ environment: Environment }>) => ({
      environment: response.data.data.environment,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Failed to get auth status.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const getLogOut: ({ redirect }: WithRedirect<{}>) => Promise<WithRedirect<{}>> =
  ({ redirect }) => {
    return axios
      .get(ApiRoutes.LOG_OUT)
      .then(() => {
        return {
          redirect: redirect,
        };
      })
      .catch((reason: ErrorResponse) => {
        const e: AppError = {
          error: {
            message:
              reason.response?.data?.message || "Failed to log user out.",
            suppressGlobalNotification: true,
            statusCode: reason.response?.status,
          },
        };
        throw e;
      });
  };

const updateEnvironment: (payload: {
  environment: Environment;
}) => Promise<{ environment: Environment }> = ({ environment }) => {
  return axios
    .post(ApiRoutes.AUTH_ENVIRONMENT, { environment })
    .then((response: ApiResponse<{ environment: Environment }>) => ({
      environment: response.data.data.environment,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Failed to update environment.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const AuthService = {
  postSSOEnabled,
  postSSOLogin,
  postLoginToken,
  postPasswordlessLogin,
  postGoogleOauthLogin,
  getAuthStatus,
  getLogOut,
  updateEnvironment,
};
export default AuthService;
