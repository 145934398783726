import React, { useEffect } from "react";
import { useDestinationVendors } from "@prequel/react";
import {
  Table,
  TableRow,
  StatusLight,
  Status,
} from "@prequel-internal/react-components";
import { useNavigate } from "react-router-dom";

import { useTypedDispatch, useTypedSelector } from "../../../store";
import Timestamp from "../../../components/Timestamp";
import VendorLogo from "../../../components/VendorLogo";
import {
  sortByCreatedAt,
  getReadableVendor,
  getVendorLogoUrl,
} from "../../../lib";
import { getReadableLastSuccessfulSync } from "../../../store/destinations";
import {
  fetchDestinations,
  selectDestinations,
} from "../../../store/destinations/destinations.duck";
import { env } from "../../../env";

const DestinationsTable = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const destinationVendors = useDestinationVendors(env.REACT_APP_API_SERVER);
  const connections = useTypedSelector(selectDestinations);

  useEffect(() => {
    dispatch(fetchDestinations());
  }, [dispatch]);

  const sortedConnections = [...(connections || [])].sort(sortByCreatedAt);
  const rows = sortedConnections.map((connection) => ({
    id: connection.id,
    cells: [
      {
        value: connection.name,
      },
      {
        value: (
          <div className="flex items-center">
            <VendorLogo
              logo_url={getVendorLogoUrl(
                connection.vendor,
                destinationVendors ?? []
              )}
            />
            {getReadableVendor(connection.vendor, destinationVendors ?? [])}
          </div>
        ),
      },
      {
        value: (
          <div className="text-gray-500">
            {getReadableLastSuccessfulSync(
              connection.last_successful_transfer_ended_at ?? undefined
            )}
          </div>
        ),
      },
      {
        value: (
          <div className="flex items-center text-gray-500">
            <StatusLight
              status={connection.is_enabled ? Status.ACTIVE : Status.INACTIVE}
            />
            {connection.is_enabled ? "Enabled" : "Disabled"}
          </div>
        ),
      },
      {
        value: <Timestamp timestamp={connection.created_at} />,
      },
    ],
  }));

  const columns = [
    "Connection Name",
    "Vendor",
    "Last Successful Sync",
    "Status",
    "Created at",
  ];

  const onRowClick = (row: TableRow) => navigate(`${row.id}`);

  return (
    <Table
      columns={columns}
      rows={rows || []}
      footer={rows ? `${rows.length} connections` : ""}
      onRowClick={onRowClick}
    />
  );
};

export default DestinationsTable;
