import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonStyle,
  ConfirmModal,
  DescriptionList,
  Modal,
} from "@prequel-internal/react-components";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  deleteSource,
  fetchSourceVendors,
  fetchSources,
  selectSource,
  selectSourceVendors,
} from "../../../store/sources/sources.duck";
import { SourceVendor, VendorField } from "../../../lib";
import VendorLogo from "../../../components/VendorLogo";

const SourceDetails = () => {
  const { connectionId } = useParams<{ connectionId: string }>();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const sourceVendors: SourceVendor[] | undefined =
    useTypedSelector(selectSourceVendors);
  const source = useTypedSelector((state) => selectSource(state, connectionId));

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const sourceVendor = sourceVendors?.find(
    ({ vendor_name }) => vendor_name === source?.vendor
  );
  const fields: { [key: string]: VendorField } | undefined = useMemo(() => {
    return sourceVendor?.fields.reduce(
      (acc, obj) => ({ ...acc, [obj.name]: obj }),
      {}
    );
  }, [sourceVendor]);

  useEffect(() => {
    dispatch(fetchSourceVendors());
    dispatch(fetchSources());
  }, [dispatch]);

  const onDelete = () => setShowDeleteModal(true);

  const onConfirmDelete = () => {
    setShowDeleteModal(false);
    if (source) {
      dispatch(
        deleteSource({
          sourceId: source.id,
          redirect: () => navigate("/sources"),
        })
      );
    }
  };

  return (
    <>
      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <ConfirmModal
          title="Delete source"
          message="Are you sure you want to delete this source? This action cannot be undone."
          onConfirm={onConfirmDelete}
          confirmButtonText="Delete"
          onCancel={() => setShowDeleteModal(false)}
        />
      </Modal>
      <div className="space-y-1">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Details</h3>
      </div>
      <div className="mt-6">
        {fields && source && (
          <DescriptionList>
            {sourceVendor && (
              <DescriptionList.Item
                label="Vendor"
                value={
                  <div className="flex items-center">
                    <VendorLogo logo_url={sourceVendor.logo_url} />
                    {sourceVendor.display_name}
                  </div>
                }
              />
            )}
            {"name" in fields && (
              <DescriptionList.Item label="Name" value={source.name} />
            )}
            {"host" in fields && (
              <DescriptionList.Item
                label={fields.host.label}
                value={source.host}
              />
            )}
            {"port" in fields && (
              <DescriptionList.Item
                label={fields.port.label}
                value={source.port ? source.port.toString() : ""}
              />
            )}
            {"database" in fields && (
              <DescriptionList.Item
                label={fields.database.label}
                value={source.database}
              />
            )}
            {"username" in fields && (
              <DescriptionList.Item
                label={fields.username.label}
                value={source.username}
              />
            )}
            {"bucket_name" in fields && (
              <DescriptionList.Item
                label={fields.bucket_name.label}
                value={source.bucket_name}
              />
            )}
            {"bucket_region" in fields && (
              <DescriptionList.Item
                label={fields.bucket_region.label}
                value={source.bucket_region}
              />
            )}
            {"bucket_access_id" in fields && (
              <DescriptionList.Item
                label={fields.bucket_access_id.label}
                value={source.bucket_access_id}
              />
            )}
            {"use_ssh_tunnel" in fields && (
              <DescriptionList.Item
                label="Uses SSH Tunnel"
                value={source.use_ssh_tunnel ? "Yes" : "No"}
              />
            )}
          </DescriptionList>
        )}
      </div>

      <div className="mt-10 divide-y divide-red-300">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Danger Zone
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Actions in this section should be considered carefully.
          </p>
        </div>
        <div className="mt-6">
          <DescriptionList type="danger">
            <DescriptionList.ButtonItem
              label="Edit source"
              description="Edit source fields. Source 'vendor and host' cannot be changed."
            >
              <Button
                text="Edit source"
                type={ButtonStyle.DANGER}
                onClick={() => navigate(`/sources/${source?.id}/edit`)}
              />
            </DescriptionList.ButtonItem>
            <DescriptionList.ButtonItem
              label="Delete source"
              description="Once you delete a source, the action cannot be undone."
            >
              <Button
                text="Delete source"
                type={ButtonStyle.DANGER}
                onClick={onDelete}
              />
            </DescriptionList.ButtonItem>
          </DescriptionList>
        </div>
      </div>
    </>
  );
};

export default SourceDetails;
