import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, takeEvery, put } from "redux-saga/effects";

import AuthService from "./auth.service";
import { AppError } from "../../axios";
import {
  RootState,
  createWorkerSaga,
  WithRedirect,
  createRedirectSaga,
} from "..";
import { Environment } from ".";

type AuthState = {
  ssoEnabled:
    | undefined
    | { state: "SUCCESS"; enabled: boolean }
    | { state: "LOADING" }
    | { state: "ERROR"; error: string };
  ssoLogin:
    | undefined
    | { state: "LOADING" | "REDIRECTING" }
    | { state: "ERROR"; error: string };
  googleoauthLogin:
    | undefined
    | { state: "LOADING" | "REDIRECTING" }
    | { state: "ERROR"; error: string };
  passwordlessLogin:
    | undefined
    | { state: "LOADING" | "SUBMITTED" }
    | { state: "ERROR"; error: string };
  authenticated: boolean | undefined;
  authorized:
    | undefined
    | { state: "AUTHORIZED" }
    | { state: "ERROR"; error: string };
  email: string | undefined;
  environment: Environment;
};

const initialState: AuthState = {
  authenticated: undefined,
  authorized: undefined,
  ssoEnabled: undefined,
  ssoLogin: undefined,
  googleoauthLogin: undefined,
  passwordlessLogin: undefined,
  email: undefined,
  environment: Environment.PROD,
};

const fetchSSOEnabledReducer: CaseReducer<AuthState, PayloadAction<string>> = (
  state,
  action
) => {
  state.ssoEnabled = { state: "LOADING" };
  state.email = action.payload;
};

const fetchSSOEnabledSuccessReducer: CaseReducer<
  AuthState,
  PayloadAction<boolean>
> = (state, action) => {
  state.ssoEnabled = { state: "SUCCESS", enabled: action.payload };
};

const fetchSSOEnabledFailureReducer: CaseReducer<
  AuthState,
  PayloadAction<AppError>
> = (state, action) => {
  state.ssoEnabled = { state: "ERROR", error: action.payload.error.message };
};

const fetchSSOLoginReducer: CaseReducer<AuthState, PayloadAction<string>> = (
  state
) => {
  state.ssoLogin = { state: "LOADING" };
};

const fetchSSOLoginSuccessReducer: CaseReducer<
  AuthState,
  PayloadAction<void>
> = (state) => {
  state.ssoLogin = { state: "REDIRECTING" }; // A successful SSO login should redirect off the page...
};

const fetchSSOLoginFailureReducer: CaseReducer<
  AuthState,
  PayloadAction<AppError>
> = (state, action) => {
  state.ssoLogin = { state: "ERROR", error: action.payload.error.message };
};

const fetchLoginTokenReducer: CaseReducer<AuthState, PayloadAction<string>> = (
  state
) => state;

const fetchLoginTokenSuccessReducer: CaseReducer<
  AuthState,
  PayloadAction<void>
> = (state) => {
  state.authenticated = true;
  state.authorized = { state: "AUTHORIZED" };
};

const fetchLoginTokenFailureReducer: CaseReducer<
  AuthState,
  PayloadAction<AppError>
> = (state, action) => {
  state.authenticated = false;
  state.authorized = { state: "ERROR", error: action.payload.error.message };
};

const fetchPasswordlessLoginReducer: CaseReducer<
  AuthState,
  PayloadAction<string>
> = (state) => {
  state.passwordlessLogin = { state: "LOADING" };
};

const fetchPasswordlessLoginSuccessReducer: CaseReducer<
  AuthState,
  PayloadAction<void>
> = (state) => {
  state.passwordlessLogin = { state: "SUBMITTED" }; // A successful passwordless login should result in a page exit...
};

const fetchPasswordlessLoginFailureReducer: CaseReducer<
  AuthState,
  PayloadAction<AppError>
> = (state, action) => {
  state.passwordlessLogin = {
    state: "ERROR",
    error: action.payload.error.message,
  };
};

const fetchGoogleOauthLoginReducer: CaseReducer<
  AuthState,
  PayloadAction<void>
> = (state) => {
  state.googleoauthLogin = { state: "LOADING" };
};

const fetchGoogleOauthLoginSuccessReducer: CaseReducer<
  AuthState,
  PayloadAction<void>
> = (state) => {
  state.googleoauthLogin = { state: "REDIRECTING" };
};

const fetchGoogleOauthLoginFailureReducer: CaseReducer<
  AuthState,
  PayloadAction<AppError>
> = (state, action) => {
  state.googleoauthLogin = {
    state: "ERROR",
    error: action.payload.error.message,
  };
};

const fetchAuthStatusReducer: CaseReducer<AuthState, PayloadAction<void>> = (
  state
) => state;

const fetchAuthStatusSuccessReducer: CaseReducer<
  AuthState,
  PayloadAction<{ environment: Environment }>
> = (state, action) => {
  state.authenticated = true;
  state.environment = action.payload.environment;
};

const fetchAuthStatusFailureReducer: CaseReducer<
  AuthState,
  PayloadAction<AppError>
> = (state) => {
  state.authenticated = false;
};

const fetchLogOutReducer: CaseReducer<
  AuthState,
  PayloadAction<WithRedirect<{}>>
> = (state) => state;

const fetchLogOutSuccessReducer: CaseReducer<
  AuthState,
  PayloadAction<WithRedirect<{}>>
> = (state) => state;

const fetchLogOutFailureReducer: CaseReducer<
  AuthState,
  PayloadAction<AppError>
> = (state) => state;

const setUserUnauthorizedReducer: CaseReducer<
  AuthState,
  PayloadAction<void>
> = (state) => {
  state.authenticated = false;
  state.ssoEnabled = undefined;
};

const resetAuthReducer: CaseReducer<AuthState, PayloadAction<void>> = (
  state
) => {
  state = initialState;
};

const resetAuthErrorsReducer: CaseReducer<AuthState, PayloadAction<void>> = (
  state
) => {
  state.ssoEnabled = undefined;
  state.ssoLogin = undefined;
  state.googleoauthLogin = undefined;
  state.passwordlessLogin = undefined;
};

const updateEnvironmentReducer: CaseReducer<
  AuthState,
  PayloadAction<{ environment: Environment }>
> = (state) => state;

const updateEnvironmentSuccessReducer: CaseReducer<
  AuthState,
  PayloadAction<{ environment: Environment }>
> = (state, action) => {
  state.environment = action.payload.environment;
};

const updateEnvironmentFailureReducer: CaseReducer<
  AuthState,
  PayloadAction<AppError>
> = (state) => state;

function* watchFetchSSOEnabled() {
  yield takeEvery(
    fetchSSOEnabled.type,
    createWorkerSaga(
      fetchSSOEnabled,
      fetchSSOEnabledSuccess,
      fetchSSOEnabledFailure,
      AuthService.postSSOEnabled
    )
  );
}

function* watchFetchSSOLogin() {
  yield takeEvery(
    fetchSSOLogin,
    createWorkerSaga(
      fetchSSOLogin,
      fetchSSOLoginSuccess,
      fetchSSOLoginFailure,
      AuthService.postSSOLogin
    )
  );
}

function* watchFetchLoginToken() {
  yield takeEvery(
    fetchLoginToken.type,
    createWorkerSaga(
      fetchLoginToken,
      fetchLoginTokenSuccess,
      fetchLoginTokenFailure,
      AuthService.postLoginToken
    )
  );
}

function* watchFetchPasswordlessLogin() {
  yield takeEvery(
    fetchPasswordlessLogin,
    createWorkerSaga(
      fetchPasswordlessLogin,
      fetchPasswordlessLoginSuccess,
      fetchPasswordlessLoginFailure,
      AuthService.postPasswordlessLogin
    )
  );
}

function* watchFetchGoogleOauthLogin() {
  yield takeEvery(
    fetchGoogleOauthLogin,
    createWorkerSaga(
      fetchGoogleOauthLogin,
      fetchGoogleOauthLoginSuccess,
      fetchGoogleOauthLoginFailure,
      AuthService.postGoogleOauthLogin
    )
  );
}

function* watchFetchGoogleOauthLoginSuccess() {
  yield takeEvery(fetchGoogleOauthLoginSuccess.type, createRedirectSaga());
}

function* watchFetchAuthStatus() {
  yield takeEvery(
    fetchAuthStatus.type,
    createWorkerSaga(
      fetchAuthStatus,
      fetchAuthStatusSuccess,
      fetchAuthStatusFailure,
      AuthService.getAuthStatus
    )
  );
}

function* watchUnauthorizedRequest() {
  yield takeEvery(
    "*",
    function* ({ payload }: { type: string; payload: AppError }) {
      if (payload?.error?.statusCode === 401) {
        yield put(authSlice.actions.setUserUnauthorized());
      }
    }
  );
}

function* watchFetchLogOut() {
  yield takeEvery(
    fetchLogOut.type,
    createWorkerSaga(
      fetchLogOut,
      fetchLogOutSuccess,
      fetchLogOutFailure,
      AuthService.getLogOut
    )
  );
}

function* watchFetchLogOutSuccess() {
  yield takeEvery(fetchLogOutSuccess.type, function* () {
    yield put(resetAuth());
  });
  yield takeEvery(fetchLogOutSuccess.type, createRedirectSaga());
}

function* watchUpdateEnvironment() {
  yield takeEvery(
    updateEnvironment.type,
    createWorkerSaga(
      updateEnvironment,
      updateEnvironmentSuccess,
      updateEnvironmentFailure,
      AuthService.updateEnvironment
    )
  );
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    fetchSSOEnabled: fetchSSOEnabledReducer,
    fetchSSOEnabledSuccess: fetchSSOEnabledSuccessReducer,
    fetchSSOEnabledFailure: fetchSSOEnabledFailureReducer,
    fetchSSOLogin: fetchSSOLoginReducer,
    fetchSSOLoginSuccess: fetchSSOLoginSuccessReducer,
    fetchSSOLoginFailure: fetchSSOLoginFailureReducer,
    fetchLoginToken: fetchLoginTokenReducer,
    fetchLoginTokenSuccess: fetchLoginTokenSuccessReducer,
    fetchLoginTokenFailure: fetchLoginTokenFailureReducer,
    fetchPasswordlessLogin: fetchPasswordlessLoginReducer,
    fetchPasswordlessLoginSuccess: fetchPasswordlessLoginSuccessReducer,
    fetchPasswordlessLoginFailure: fetchPasswordlessLoginFailureReducer,
    fetchGoogleOauthLogin: fetchGoogleOauthLoginReducer,
    fetchGoogleOauthLoginSuccess: fetchGoogleOauthLoginSuccessReducer,
    fetchGoogleOauthLoginFailure: fetchGoogleOauthLoginFailureReducer,
    resetAuth: resetAuthReducer,
    resetAuthErrors: resetAuthErrorsReducer,
    fetchAuthStatus: fetchAuthStatusReducer,
    fetchAuthStatusSuccess: fetchAuthStatusSuccessReducer,
    fetchAuthStatusFailure: fetchAuthStatusFailureReducer,
    setUserUnauthorized: setUserUnauthorizedReducer,
    fetchLogOut: fetchLogOutReducer,
    fetchLogOutSuccess: fetchLogOutSuccessReducer,
    fetchLogOutFailure: fetchLogOutFailureReducer,
    updateEnvironment: updateEnvironmentReducer,
    updateEnvironmentSuccess: updateEnvironmentSuccessReducer,
    updateEnvironmentFailure: updateEnvironmentFailureReducer,
  },
});

export const {
  fetchSSOEnabled,
  fetchSSOEnabledSuccess,
  fetchSSOEnabledFailure,
  fetchSSOLogin,
  fetchSSOLoginSuccess,
  fetchSSOLoginFailure,
  fetchLoginToken,
  fetchLoginTokenSuccess,
  fetchLoginTokenFailure,
  fetchPasswordlessLogin,
  fetchPasswordlessLoginSuccess,
  fetchPasswordlessLoginFailure,
  fetchGoogleOauthLogin,
  fetchGoogleOauthLoginSuccess,
  fetchGoogleOauthLoginFailure,
  resetAuth,
  resetAuthErrors,
  fetchAuthStatus,
  fetchAuthStatusSuccess,
  fetchAuthStatusFailure,
  fetchLogOut,
  fetchLogOutSuccess,
  fetchLogOutFailure,
  updateEnvironment,
  updateEnvironmentSuccess,
  updateEnvironmentFailure,
} = authSlice.actions;

export const selectSSOEnabled = ({ auth }: RootState) => auth.ssoEnabled;
export const selectSSOLogin = ({ auth }: RootState) => auth.ssoLogin;
export const selectPasswordlessLogin = ({ auth }: RootState) =>
  auth.passwordlessLogin;
export const selectGoogleOauthLogin = ({ auth }: RootState) =>
  auth.googleoauthLogin;
export const selectAuthenticated = ({ auth }: RootState) => auth.authenticated;
export const selectAuthorized = ({ auth }: RootState) => auth.authorized;
export const selectEmail = ({ auth }: RootState) => auth.email;
export const selectEnvironment = ({ auth }: RootState) => auth.environment;

export function* authSaga() {
  yield all([
    watchFetchSSOEnabled(),
    watchFetchSSOLogin(),
    watchFetchLoginToken(),
    watchFetchPasswordlessLogin(),
    watchFetchGoogleOauthLogin(),
    watchFetchGoogleOauthLoginSuccess(),
    watchFetchAuthStatus(),
    watchUnauthorizedRequest(),
    watchFetchLogOut(),
    watchFetchLogOutSuccess(),
    watchUpdateEnvironment(),
  ]);
}
export default authSlice.reducer;
