import { AwsTrustPolicy, FrequencyMinutes } from "@prequel/react";

export type Organization = {
  app_users: string[];
  default_frequency_minutes: FrequencyMinutes;
  fastest_allowed_frequency_minutes: FrequencyMinutes;
  github_branch_name: string;
  github_staging_branch_name: string;
  github_organization: string;
  github_repository: string;
  id: string;
  name: string;
  sources_schema_tenanted: boolean;
  sso_enabled: boolean;
  aws_iam_role_id?: string;
  aws_iam_role_name?: string;
  gcp_iam_role_id?: string;
  gcp_iam_role_name?: string;
};

export const buildAwsTrustPolicy: (
  o: Organization | undefined
) => AwsTrustPolicy = (org) => {
  if (org?.gcp_iam_role_name) {
    return {
      Version: "2012-10-17",
      Statement: [
        {
          Effect: "Allow",
          Action: ["sts:AssumeRoleWithWebIdentity"],
          Principal: {
            Federated: "accounts.google.com",
          },
          Condition: {
            StringEquals: {
              "accounts.google.com:sub": org.gcp_iam_role_name,
            },
          },
        },
      ],
    };
  }

  if (org?.aws_iam_role_id) {
    return {
      Version: "2012-10-17",
      Statement: [
        {
          Effect: "Allow",
          Action: ["sts:AssumeRole"],
          Principal: {
            AWS: org.aws_iam_role_id,
          },
        },
      ],
    };
  }

  return {
    Version: "2012-10-17",
    Statement: [
      {
        Effect: "Allow",
        Action: ["sts:AssumeRoleWithWebIdentity"],
        Principal: {
          Federated: "accounts.google.com",
        },
        Condition: {
          StringEquals: {
            "accounts.google.com:sub": "<some_service_account_identifier>",
          },
        },
      },
    ],
  };
};
