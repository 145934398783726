import React from "react";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  closeLightbox,
  selectLightbox,
} from "../../../store/lightbox/lightbox.duck";

const Lightbox = () => {
  const dispatch = useTypedDispatch();
  const { isOpen, src, alt } = useTypedSelector(selectLightbox);

  const handleBackgroundClick = () => {
    // Close the modal if the background is clicked
    dispatch(closeLightbox());
  };

  const handleImageClick = (e) => {
    // Stop propagation to prevent closing modal when image is clicked
    e.stopPropagation();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
      onClick={handleBackgroundClick}
    >
      <div className="relative">
        <button
          className="absolute top-2 right-2 text-white text-2xl"
          onClick={() => dispatch(closeLightbox())}
        >
          &times;
        </button>
        <img
          onClick={handleImageClick}
          src={src}
          alt={alt}
          className="h-auto w-auto max-h-[80vh]"
        />
      </div>
    </div>
  );
};

export default Lightbox;
