import React, { useEffect, useState } from "react";
import {
  Navigate,
  NavLink,
  useNavigate,
  useParams,
  useRoutes,
} from "react-router-dom";
import { DestinationType } from "@prequel/react";
import {
  ButtonStyle,
  Button,
  MenuItemStyle,
  Modal,
  PageHeading,
  Tabs,
  Toggle,
  checkTabIsActive,
  Spinner,
} from "@prequel-internal/react-components";

import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  createTransfer,
  selectIsPostingTransfer,
} from "../../../store/transfers/transfers.duck";
import TransfersList from "../TransfersList";
import {
  fetchDestinations,
  selectDestination,
  updateDestination,
} from "../../../store/destinations/destinations.duck";
import DestinationDetails from "../DestinationDetails";
import DestinationConnectionDetails from "../DestinationConnectionDetails";
import SyncSpecificModelsModal from "../SyncSpecificModelsModal";
import { fetchRecipients } from "../../../store/recipients/recipients.duck";

const ThirdPartyConnectionPage = () => {
  const { connectionId } = useParams<{ connectionId: string }>();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const connection = useTypedSelector((state) =>
    selectDestination(state, connectionId)
  );
  const [showSelectiveSyncModal, setShowSelectiveSyncModal] = useState(false);
  const isPostingTransfer = useTypedSelector(selectIsPostingTransfer);

  useEffect(() => {
    dispatch(fetchDestinations());
    dispatch(fetchRecipients());
  }, [dispatch, connectionId]);

  const tabs = [
    <NavLink key="General" to="" className={checkTabIsActive} replace end>
      General
    </NavLink>,
    <NavLink
      key="Transfers"
      to="transfers"
      className={checkTabIsActive}
      replace
      end
    >
      Transfers
    </NavLink>,
    <NavLink
      key="Connection"
      to="connection"
      className={checkTabIsActive}
      replace
      end
    >
      Connection
    </NavLink>,
  ];

  const element = useRoutes([
    {
      path: "/",
      element: <DestinationDetails />,
    },
    {
      path: "/transfers",
      element: <TransfersList />,
    },
    {
      path: "/connection",
      element: <DestinationConnectionDetails />,
    },
    {
      path: "*",
      element: <Navigate to="" replace />,
    },
  ]);

  const syncButtonMenuItems = [
    {
      label: "Select models to sync",
      style: MenuItemStyle.PRIMARY,
      onClick: () => setShowSelectiveSyncModal(true),
    },
  ];

  if (connection === undefined) {
    // Still loading connections
    return <Spinner />;
  }

  if (connection === null) {
    return <Navigate to={"/destinations"} replace />;
  }

  const onSyncNow = () =>
    dispatch(
      createTransfer({ destinationId: connection.id, fullRefresh: false })
    );

  const setEnabled = (isEnabled: boolean) =>
    dispatch(
      updateDestination({
        destinationId: connection.id,
        fields: { is_enabled: isEnabled },
        redirect: () => true, // do not need redirect
      })
    );

  return (
    /* eslint-disable */
    <>
      {connection.type === DestinationType.ExistingDestination && (
        <Modal
          open={showSelectiveSyncModal}
          onClose={() => setShowSelectiveSyncModal(false)}
        >
          <SyncSpecificModelsModal
            destination={connection}
            fullRefresh={false}
            onCancel={() => setShowSelectiveSyncModal(false)}
            onConfirm={() => setShowSelectiveSyncModal(false)}
          />
        </Modal>
      )}
      {/* /* eslint-enable */}
      <PageHeading title={connection.name} onGoBack={() => navigate(-1)}>
        <Button
          text={
            isPostingTransfer ? (
              <div className="flex">
                <Spinner.Inline className="mr-3 text-emerald-600" /> Starting
                Sync
              </div>
            ) : (
              "Sync Now"
            )
          }
          type={ButtonStyle.TERTIARY}
          onClick={onSyncNow}
          menuItems={
            connection.type === DestinationType.ExistingDestination
              ? syncButtonMenuItems
              : undefined
          }
        />
        <Toggle
          label="Enabled"
          enabled={connection.is_enabled}
          setEnabled={setEnabled}
        />
      </PageHeading>
      <div className="flex-1">
        <div className="relative max-w-4xl mx-auto">
          <div className="pt-0 pb-16">
            <div className="pb-6">
              {/* Tabs */}
              <Tabs tabs={tabs} />
              {element}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdPartyConnectionPage;
