import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonStyle,
  Checkbox,
  Form,
  FormField,
} from "@prequel-internal/react-components";

import { Recipient } from "../../../store/recipients";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import { createRecipient } from "../../../store/recipients/recipients.duck";
import { useNavigate } from "react-router-dom";
import {
  fetchProducts,
  selectProducts,
} from "../../../store/products/products.duck";

const RecipientForm = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const products = useTypedSelector(selectProducts);
  const [recipient, setRecipient] = useState<Recipient>({
    name: "",
    schema: "",
    id_in_provider_system: "",
    products: [],
  });
  const [availableProducts, setAvailableProducts] = useState<string[]>();
  const [productsError, setProductsError] = useState<string>();

  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    if (products) {
      const opts = products.map(({ product_name }) => product_name);
      setAvailableProducts(opts);
      // pre-select the default product if it exists
      setField(
        "products",
        opts.find((o) => o === "default") ? ["default"] : []
      );
    }
  }, [products]);

  const setField = (key: keyof Recipient, value: string | string[]) => {
    setRecipient((oldRecipient: Recipient) => ({
      ...oldRecipient,
      [key]: value,
    }));
  };

  const updateProducts = (isEnabled: boolean, productName: string) => {
    let updatedProducts: string[] = [];
    if (isEnabled) {
      updatedProducts = [...recipient.products, productName];
    } else {
      updatedProducts = recipient.products.filter((p) => p !== productName);
    }
    setProductsError(undefined);
    setField("products", updatedProducts);
  };

  const onSubmit = () => {
    if (availableProducts && recipient.products.length === 0) {
      setProductsError("Must select at least one product");
      return;
    }

    dispatch(
      createRecipient({
        recipient,
        redirect: () => navigate("/export/recipients"),
      })
    );
  };

  return (
    <Form className="space-y-4" onSubmit={onSubmit} submitButtonRef={buttonRef}>
      <FormField
        label="Name"
        id="name"
        type="text"
        subtext="Descriptive name for this Recipient. This will only be visible internally and is only used as a reference."
        value={recipient.name}
        onChangeHandler={(value: string) => setField("name", value)}
        required
      />
      <FormField
        label="ID in Provider System"
        id="id_in_provider_system"
        type="text"
        subtext="Customer ID by which the source data will be filtered. In other words, this is the ID of the target customer in your source database. An engineer or database administrator can provide the unique value for each recipient."
        value={recipient.id_in_provider_system}
        onChangeHandler={(value: string) =>
          setField("id_in_provider_system", value)
        }
        required
      />
      {availableProducts && (
        <>
          <label className="block text-sm font-medium text-gray-700">
            Select what products the recipient will receive
          </label>
          {availableProducts.map((p) => (
            <Checkbox
              key={p}
              id={p}
              label={p}
              checked={recipient.products.includes(p)}
              setChecked={(isChecked: boolean) => updateProducts(isChecked, p)}
            />
          ))}
          {productsError && (
            <p className="mt-1 text-xs font-medium text-red-600">
              {productsError}
            </p>
          )}
        </>
      )}
      <div>
        <Button
          className="mt-6"
          ref={buttonRef}
          type={ButtonStyle.PRIMARY}
          text="Create Recipient"
          submit
        />
      </div>
    </Form>
  );
};

export default RecipientForm;
