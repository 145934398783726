import React from "react";

import TestDestinationConnection from "../TestDestinationConnection";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../../store";
import { selectDestination } from "../../../store/destinations/destinations.duck";

const DestinationConnectionDetails = () => {
  const { connectionId } = useParams<{ connectionId: string }>();
  const destination = useTypedSelector((state) =>
    selectDestination(state, connectionId)
  );

  if (!destination) {
    return <div>The destination could not be found.</div>;
  }

  return <TestDestinationConnection existingDestination={destination} />;
};

export default DestinationConnectionDetails;
