import React from "react";
import { Destination, FormField } from "@prequel/react";
import ConstWrapper from "./ConstWrapper";
import InternalWrapper from "./InternalWrapper";
import SelectWrapper from "./SelectWrapper";
import InputWrapper from "./InputWrapper";
import TextAreaWrapper from "./TextAreaWrapper";
import RadioWrapper from "./RadioWrapper";

type WrapperProps = {
  field: FormField;
  destination: Destination;
  setDestinationField: (
    key: keyof Destination,
    value: string | boolean | string[] | number
  ) => void;
  disabled: boolean;
  isEditing: boolean;
};

const WrapperSelector: React.FC<WrapperProps> = ({
  field,
  destination,
  setDestinationField,
  disabled,
  isEditing,
}) => {
  if (field.const) {
    return (
      <ConstWrapper
        field={field}
        destination={destination}
        setDestinationField={setDestinationField}
        isEditing={isEditing}
      />
    );
  } else if (field.internal) {
    return (
      <InternalWrapper
        field={field}
        destination={destination}
        setDestinationField={setDestinationField}
        disabled={disabled}
        isEditing={isEditing}
      />
    );
  } else if (field.form_element === "select") {
    return (
      <SelectWrapper
        field={field}
        destination={destination}
        setDestinationField={setDestinationField}
        disabled={disabled}
        isEditing={isEditing}
      />
    );
  } else if (field.form_element === "input") {
    return (
      <InputWrapper
        field={field}
        destination={destination}
        setDestinationField={setDestinationField}
        disabled={disabled}
        isEditing={isEditing}
      />
    );
  } else if (field.form_element === "textarea") {
    return (
      <TextAreaWrapper
        field={field}
        destination={destination}
        setDestinationField={setDestinationField}
        disabled={disabled}
        isEditing={isEditing}
      />
    );
  } else if (field.form_element === "radio") {
    return (
      <RadioWrapper
        field={field}
        destination={destination}
        setDestinationField={setDestinationField}
        disabled={disabled}
        isEditing={isEditing}
      />
    );
  } else {
    return null;
  }
};

export default WrapperSelector;
