import React, { useEffect, useMemo, useRef, useState } from "react";
import { parseServiceAccountKey } from "@prequel/react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonStyle,
  Dropdown,
  DropdownListItem,
  FormField,
  TextArea,
} from "@prequel-internal/react-components";

import ExistingSource from "../../../store/sources";
import ExistingRecipient, { SourceCredential } from "../../../store/recipients";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import { selectSourceVendors } from "../../../store/sources/sources.duck";
import { SourceVendor } from "../../../lib";
import TestSourceCredentialConnection from "../TestSourceCredentialConnection";
import {
  createSourceCredential,
  selectSourceCredentialTest,
} from "../../../store/recipients/recipients.duck";

type VendorField = {
  name: string;
  is_required: boolean;
  label: string;
  placeholder: string;
  help: string;
};
type DropdownListItemWithVendor = DropdownListItem<string> & { vendor: string };
type SourceCredentialFormProps = {
  sources: ExistingSource[] | undefined;
  recipients: ExistingRecipient[] | undefined;
};
const SourceCredentialForm = ({
  sources,
  recipients,
}: SourceCredentialFormProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const sourceVendors = useTypedSelector(selectSourceVendors);
  const sourceCredentialTest = useTypedSelector(selectSourceCredentialTest);

  const [sourceOptions, setSourceOptions] =
    useState<DropdownListItemWithVendor[]>();
  const [selectedSource, setSelectedSource] =
    useState<DropdownListItemWithVendor>({
      key: "",
      text: "",
      vendor: "",
    });
  const [selectedVendor, setSelectedVendor] = useState<SourceVendor>();
  const [recipientOptions, setRecipientOptions] =
    useState<DropdownListItem<string>[]>();
  const [selectedRecipient, setSelectedRecipient] = useState<
    DropdownListItem<string>
  >({ key: "", text: "" });
  const [sourceCredential, setSourceCredential] = useState<SourceCredential>({
    source_id: "",
    recipient_id: "",
    username: "",
    password: "",
  });

  const formFields: { [key: string]: VendorField } = useMemo(() => {
    if (!selectedVendor) {
      return {};
    }
    return selectedVendor.fields.reduce(
      (acc, obj) => ({ ...acc, [obj.name]: obj }),
      {}
    );
  }, [selectedVendor]);

  const validateForm = () =>
    formRef.current ? formRef.current.reportValidity() : false;

  // On service_account_key changes, attempt to coerce the string into the JSON object
  const tokenIsValid = useMemo(
    () => !!parseServiceAccountKey(sourceCredential.password),
    [sourceCredential.password]
  );

  const setSourceCredentialField = (
    key: keyof SourceCredential,
    value: string
  ) => {
    setSourceCredential((oldCredential) => ({
      ...oldCredential,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (sources) {
      const opts = sources.map((source) => ({
        key: source.id,
        text: (
          <div className="flex items-center">
            <span className="mr-1">{source.name}</span>
            <span className="text-xs text-gray-500">({source.id})</span>
          </div>
        ),
        vendor: source.vendor,
      }));
      setSourceOptions(opts);
      setSelectedSource(opts[0]);
    }
  }, [sources]);

  useEffect(() => {
    if (sourceVendors) {
      const vendor = sourceVendors.find(
        ({ vendor_name }) => vendor_name === selectedSource.vendor
      );
      vendor && setSelectedVendor(vendor);
    }
  }, [selectedSource, sourceVendors]);

  useEffect(() => {
    setSourceCredentialField("source_id", selectedSource.key);
  }, [selectedSource]);

  useEffect(() => {
    setSourceCredentialField("recipient_id", selectedRecipient.key);
  }, [selectedRecipient]);

  useEffect(() => {
    if (recipients) {
      const opts = recipients.map((recipient) => ({
        key: recipient.id,
        text: (
          <div className="flex items-center">
            <span className="mr-1">{recipient.name}</span>
            <span className="text-xs text-gray-500">({recipient.id})</span>
          </div>
        ),
      }));
      setRecipientOptions(opts);
      setSelectedRecipient(opts[0]);
    }
  }, [recipients]);

  const onSave = () => {
    dispatch(
      createSourceCredential({
        sourceCredential,
        redirect: () => navigate("/export/recipients/source-credentials"),
      })
    );
  };

  // Intercept native form submission, prevent default, and run test
  // We use the default form submission event so that we can borrow
  // the browsers built-in support for handling missing required fields
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSave();
  };

  return (
    <div className="pb-16">
      <form className="space-y-8" onSubmit={onSubmit} ref={formRef}>
        <div className="space-y-4">
          {sourceOptions && (
            <Dropdown
              label={"Source"}
              items={sourceOptions}
              selectedItem={selectedSource}
              setSelectedItem={setSelectedSource}
              disabled={sourceCredentialTest.status === "processing"}
            />
          )}
          {recipientOptions && (
            <Dropdown
              label={"Recipient"}
              items={recipientOptions}
              selectedItem={selectedRecipient}
              setSelectedItem={setSelectedRecipient}
              disabled={sourceCredentialTest.status === "processing"}
            />
          )}
        </div>
        <div className="h-px w-full bg-gray-200"></div> {/* Divider  */}
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Enter the source credentials
            </label>
            {selectedVendor && (
              <div className="mt-1">
                <p className="mt-1 text-sm text-gray-500">
                  {`Provide the details shared by the owner of the ${selectedVendor.display_name} destination
            in the form below. For assistance, `}
                  <a
                    href={selectedVendor.docs}
                    target="_blank"
                    rel="noreferrer"
                    className="font-medium text-primary-600 hover:text-primary-500"
                  >
                    view our documentation on {selectedVendor.display_name}.
                  </a>
                </p>
              </div>
            )}
          </div>
          <FormField
            id="username"
            type="text"
            label="Username"
            placeholder={formFields?.username?.placeholder}
            subtext={formFields?.username?.help}
            value={sourceCredential.username}
            onChangeHandler={(value: string) => {
              setSourceCredentialField("username", value);
            }}
            required={formFields?.username?.is_required}
            disabled={sourceCredentialTest.status === "processing"}
          />
          {"password" in formFields && (
            <FormField
              id="password"
              type="password"
              label="Password"
              placeholder={formFields?.password?.placeholder}
              subtext={formFields?.password?.help}
              value={sourceCredential.password}
              onChangeHandler={(value: string) => {
                setSourceCredentialField("password", value);
              }}
              required={formFields?.password?.is_required}
              disabled={sourceCredentialTest.status === "processing"}
            />
          )}
          {"service_account_key" in formFields && (
            <TextArea
              id="service_account_key"
              placeholder={formFields?.service_account_key?.placeholder}
              subtext={formFields?.service_account_key?.help}
              value={sourceCredential.password}
              onChangeHandler={(value: string) => {
                setSourceCredentialField("password", value);
              }}
              invalid={!tokenIsValid}
              required={formFields?.service_account_key?.is_required}
              disabled={sourceCredentialTest.status === "processing"}
            />
          )}
          <TestSourceCredentialConnection
            beforeSubmitTest={validateForm}
            sourceCredential={sourceCredential}
          />
        </div>
      </form>
      <div className="flex justify-end mt-8">
        <Button
          type={
            !(sourceCredentialTest.status === "success")
              ? ButtonStyle.TERTIARY
              : ButtonStyle.PRIMARY
          }
          disabled={!(sourceCredentialTest.status === "success")}
          onClick={onSave}
          text="Save Source Credential"
        />
      </div>
    </div>
  );
};

export default SourceCredentialForm;
