import { DropdownListItem } from "@prequel-internal/react-components";
import StagingIcon from "../../assets/icons/StagingIcon";
import ProdIcon from "../../assets/icons/ProdIcon";

export enum Environment {
  STAGING = "staging",
  PROD = "prod",
}

export const dropdownEnvironments: DropdownListItem<Environment>[] = [
  {
    key: Environment.STAGING,
    text: "Staging",
    icon: StagingIcon,
  },
  {
    key: Environment.PROD,
    text: "Production",
    icon: ProdIcon,
  },
];
