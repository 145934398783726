import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "@prequel-internal/react-components";
import { Destination, FormField } from "@prequel/react";

type SSHPublicKeyProps = {
  field: FormField;
  destination: Destination;
  setDestinationField: (
    key: keyof Destination,
    value: string | boolean | string[]
  ) => void;
  isEditing: boolean;
};
const SSHPublicKey = ({
  field,
  destination,
  setDestinationField,
  isEditing,
}: SSHPublicKeyProps) => {
  const [displayValue, setDisplayValue] = useState<string>();
  const destinationValue = destination[field.name];

  useEffect(() => {
    // Core logical checks to avoid type complaints within the branch: confirms we are looking at the ssh_public_key field and we have the necessary values
    // debugger; // eslint-disable-line
    if (typeof field.const === "string") {
      if (isEditing) {
        // If we are editing and the destination does not have a key, display const and set the key on the destination
        if (destinationValue === undefined) {
          setDestinationField(field.name, field.const);
          setDisplayValue(field.const);
        } else {
          // If we are editing and the destination has a key, display it
          setDisplayValue(destinationValue.toString());
        }
      } else {
        // If we're not editing, display the key and set it on the destination
        setDestinationField(field.name, field.const);
        setDisplayValue(field.const);
      }
    }
  }, [field.const]);

  if (!displayValue) {
    return <></>;
  }

  return (
    <>
      <div className="w-full shadow-sm inline-flex rounded-md pl-3 pr-1 py-2 mt-1 text-gray-500 text-sm bg-gray-50 border border-gray-300 font-mono">
        <div className="w-full truncate mr-3">{displayValue}</div>
        <CopyToClipboard textToCopy={displayValue} />
      </div>
      {field.description && (
        <p
          className="mt-2 text-xs text-gray-400 whitespace-pre-line"
          id="description"
        >
          {field.description}
        </p>
      )}
    </>
  );
};

export default SSHPublicKey;
