import { createSlice, PayloadAction, CaseReducer } from "@reduxjs/toolkit";

interface LightboxState {
  isOpen: boolean;
  src: string | undefined;
  alt: string | undefined;
}

const initialState: LightboxState = {
  isOpen: false,
  src: undefined,
  alt: undefined,
};

const openLightboxReducer: CaseReducer<
  LightboxState,
  PayloadAction<{ src: string; alt: string }>
> = (state, action) => {
  state.isOpen = true;
  state.src = action.payload.src;
  state.alt = action.payload.alt;
};

const closeLightboxReducer: CaseReducer<LightboxState> = (state) => {
  state.isOpen = false;
  state.src = undefined;
  state.alt = undefined;
};

const lightboxSlice = createSlice({
  name: "lightbox",
  initialState,
  reducers: {
    openLightbox: openLightboxReducer,
    closeLightbox: closeLightboxReducer,
  },
});

export const selectLightbox = (state: { lightbox: LightboxState }) =>
  state.lightbox;

export const { openLightbox, closeLightbox } = lightboxSlice.actions;

export default lightboxSlice.reducer;
