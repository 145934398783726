import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({
  scrollElementRef,
}: {
  scrollElementRef: React.RefObject<HTMLElement>;
}) => {
  const location = useLocation();

  // Attach a ref to the scroll window
  // When the path changes, scroll to top of the page. (Without this, navigation using the react-router does not scroll the page to the top)
  useLayoutEffect(() => {
    if (scrollElementRef.current) {
      scrollElementRef.current.scrollTo(0, 0);
    }
  }, [scrollElementRef, location.pathname]);

  return null;
};

export default ScrollToTop;
