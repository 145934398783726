import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  AwsTrustPolicy,
  IamRole as GcpIamRole,
  parseServiceAccountKey,
} from "@prequel/react";
import {
  Button,
  ButtonStyle,
  Dropdown,
  FormField,
  PasswordDot,
  Spinner,
  Toggle,
  TextArea,
} from "@prequel-internal/react-components";

import {
  DropdownListItemWithFields,
  SourceVendor,
  VendorField,
} from "../../../lib";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  createSource,
  fetchSources,
  fetchSourceVendors,
  selectSource,
  selectSourceTest,
  selectSourceVendors,
  updateSource,
} from "../../../store/sources/sources.duck";
import {
  Source,
  prepareSource,
  PreparedSource,
  convertToSource,
  computeChangedFields,
  SourceType,
} from "../../../store/sources";
import VendorLogo from "../../../components/VendorLogo";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import SSHTunnelForm from "./SSHTunnelForm";
import TestSourceConnection from "../TestSourceConnection";
import IamTrustPolicy from "./IamTrustPolicy";
import IamRole, { IamRoleKind } from "./IamRole";
import { fetchOrg, selectOrg } from "../../../store/org/org.duck";
import { buildAwsTrustPolicy } from "../../../store/org";

const SourceForm = () => {
  const navigate = useNavigate();
  const { connectionId } = useParams<{ connectionId: string }>();
  const sourceToEdit = useTypedSelector((state) =>
    selectSource(state, connectionId)
  );
  const org = useTypedSelector(selectOrg);
  const isEditing = !!sourceToEdit; // Evaluates to true if sourceToEdit exists, otherwise evaluates to false
  const formRef = useRef<HTMLFormElement>(null);
  const dispatch = useTypedDispatch();
  const sourceVendors = useTypedSelector(selectSourceVendors);
  const sourceTest = useTypedSelector(selectSourceTest);

  const [vendorOptions, setVendorOptions] =
    useState<DropdownListItemWithFields[]>();
  const [selectedVendor, setSelectedVendor] =
    useState<DropdownListItemWithFields>({
      key: "",
      text: "",
      fields: [],
      docs: "",
      uses_staging_bucket: false,
      uses_service_account: false,
      supports_ssh_tunnel: false,
    });
  const [source, setSource] = useState<Source>({
    type: SourceType.Source,
    vendor: selectedVendor.key,
    name: "",
    host: "",
    port: "",
    database: "",
    username: "",
    password: "",
    service_account_key: "",
    bucket_vendor: "",
    bucket_name: "",
    bucket_region: "",
    bucket_access_id: "",
    bucket_secret_key: "",
    ssh_public_key: "",
    ssh_tunnel_host: "",
    ssh_tunnel_port: "",
    ssh_tunnel_username: "",
    use_ssh_tunnel: false,
    wallet: "",
  });

  const formFields: { [key: string]: VendorField } = useMemo(() => {
    return selectedVendor.fields.reduce(
      (acc, obj) => ({ ...acc, [obj.name]: obj }),
      {}
    );
  }, [selectedVendor]);

  // On service_account_key changes, attempt to coerce the string into the JSON object
  const tokenIsValid = useMemo(
    () => !!parseServiceAccountKey(source.service_account_key),
    [source.service_account_key]
  );

  const preparedSource: PreparedSource = useMemo(
    () => prepareSource(source),
    [source]
  );

  const validateForm = () =>
    formRef.current ? formRef.current.reportValidity() : false;

  const setSourceField = (
    key: keyof Source,
    value: string | string[] | boolean
  ) => {
    setSource((oldSource) => ({
      ...oldSource,
      [key]: value,
    }));
  };

  useEffect(() => {
    dispatch(fetchSourceVendors());
    dispatch(fetchSources());
    dispatch(fetchOrg());
  }, [dispatch]);

  useEffect(() => {
    setSourceField("vendor", selectedVendor.key);
  }, [selectedVendor]);

  useEffect(() => {
    if (sourceToEdit) {
      setSource(convertToSource(sourceToEdit));
      const option = vendorOptions?.find(
        ({ key }) => key === sourceToEdit.vendor
      );
      option && setSelectedVendor(option);
    }
  }, [sourceToEdit]);

  useEffect(() => {
    if (sourceVendors) {
      const vendors = sourceVendors.map((s: SourceVendor) => {
        const icon = () => <VendorLogo logo_url={s.logo_url} />;
        return {
          ...s,
          key: s.vendor_name,
          text: s.display_name,
          icon,
          uses_service_account: false,
        };
      });
      setVendorOptions(vendors);
      const opt =
        vendors.find(({ key }) => key === sourceToEdit?.vendor) ?? vendors[0];
      setSelectedVendor(opt);
    }
  }, [sourceVendors]);

  const onSave = () => {
    if (isEditing) {
      const changed = computeChangedFields(sourceToEdit, preparedSource);
      dispatch(
        updateSource({
          sourceId: sourceToEdit.id,
          source: changed,
          redirect: () => navigate("/export/sources"),
        })
      );
    } else {
      dispatch(
        createSource({
          source: preparedSource,
          redirect: () => navigate("/export/sources"),
        })
      );
    }
  };

  // Intercept native form submission, prevent default, and run test
  // We use the default form submission event so that we can borrow the browsers built-in support for handling missing required fields
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSave();
  };

  const awsTrustPolicy = buildAwsTrustPolicy(org);
  const gcpIamRole: GcpIamRole = {
    id:
      org?.gcp_iam_role_id ||
      "<some-service-account-name>@prql-prod.iam.gserviceaccount.com",
  };

  if (connectionId && sourceToEdit === undefined) {
    return <Spinner />;
  }

  if (connectionId && sourceToEdit === null) {
    return <Navigate to="/export/sources" replace />;
  }

  return (
    <div className="pb-16">
      <form className="space-y-8" onSubmit={onSubmit} ref={formRef}>
        <div className="space-y-4">
          {vendorOptions && (
            <Dropdown
              label={"Source type"}
              items={vendorOptions}
              selectedItem={selectedVendor}
              setSelectedItem={setSelectedVendor}
              disabled={isEditing}
            />
          )}
          <FormField
            label="Name the source"
            id="name"
            type="text"
            subtext="Descriptive name for this source."
            value={source.name}
            onChangeHandler={(value: string) => setSourceField("name", value)}
            required
            disabled={sourceTest.status === "processing"}
          />
        </div>
        <div className="h-px w-full bg-gray-200"></div> {/* Divider  */}
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Enter the source credentials
            </label>
            <div className="mt-1">
              <p className="mt-1 text-sm text-gray-500">
                {`Provide the details of the ${selectedVendor.text} source
            in the form below. For assistance, `}
                <a
                  href={selectedVendor.docs}
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium text-primary-600 hover:text-primary-500"
                >
                  view our documentation on {selectedVendor.text}.
                </a>
              </p>
            </div>
          </div>
          {"host" in formFields && (
            <FormField
              id="host"
              type="text"
              label={formFields.host.label}
              placeholder={formFields.host.placeholder}
              subtext={formFields.host.help}
              value={source.host}
              onChangeHandler={(value: string) => {
                setSourceField("host", value);
              }}
              required={formFields.host.is_required}
              disabled={sourceTest.status === "processing" || isEditing}
            />
          )}
          {"port" in formFields && (
            <FormField
              id="port"
              type="text"
              label={formFields.port.label}
              placeholder={formFields.port.placeholder}
              subtext={formFields.port.help}
              value={source.port}
              onChangeHandler={(value: string) => {
                setSourceField("port", value);
              }}
              required={formFields.port.is_required}
              disabled={sourceTest.status === "processing"}
            />
          )}
          {"database" in formFields && (
            <FormField
              id="database"
              type="text"
              label={formFields.database.label}
              placeholder={formFields.database.placeholder}
              subtext={formFields.database.help}
              value={source.database}
              onChangeHandler={(value: string) => {
                setSourceField("database", value);
              }}
              required={formFields.database.is_required}
              disabled={sourceTest.status === "processing"}
            />
          )}
          {"username" in formFields && (
            <FormField
              id="username"
              type="text"
              label={formFields.username.label}
              placeholder={formFields.username.placeholder}
              subtext={formFields.username.help}
              value={source.username}
              onChangeHandler={(value: string) => {
                setSourceField("username", value);
              }}
              required={formFields.username.is_required}
              disabled={sourceTest.status === "processing"}
            />
          )}
          {"password" in formFields && (
            <FormField
              id="password"
              type="password"
              label={formFields.password.label}
              placeholder={
                isEditing
                  ? PasswordDot.repeat(20)
                  : formFields.password.placeholder
              }
              overwriteOnly={isEditing}
              subtext={formFields.password.help}
              value={source.password}
              onChangeHandler={(value: string) => {
                setSourceField("password", value);
              }}
              required={formFields.password.is_required && !isEditing}
              disabled={sourceTest.status === "processing"}
            />
          )}
          {"service_account_key" in formFields && (
            <TextArea
              id="service_account_key"
              placeholder={
                isEditing
                  ? PasswordDot.repeat(184)
                  : formFields.service_account_key.placeholder
              }
              overwriteOnly={isEditing}
              subtext={formFields.service_account_key.help}
              value={source.service_account_key}
              onChangeHandler={(value: string) => {
                setSourceField("service_account_key", value);
              }}
              invalid={!tokenIsValid}
              required={
                formFields.service_account_key.is_required && !isEditing
              }
              disabled={sourceTest.status === "processing"}
            />
          )}
          {"gcp_iam_role" in formFields && (
            <>
              <IamRole role={gcpIamRole} kind={IamRoleKind.FirstParty} />
              <FormField
                id="gcp_iam_role"
                type="text"
                label={formFields.gcp_iam_role.label}
                placeholder={formFields.gcp_iam_role.placeholder}
                subtext={formFields.gcp_iam_role.help}
                value={source?.gcp_iam_role}
                onChangeHandler={(value: string) => {
                  setSourceField("gcp_iam_role", value);
                }}
                required={formFields.gcp_iam_role.is_required}
                disabled={sourceTest.status === "processing"}
              />
            </>
          )}
          {"aws_iam_role" in formFields && (
            <>
              <IamTrustPolicy
                iamTrustPolicy={awsTrustPolicy}
                kind="FIRSTPARTYSOURCE"
              />
              <FormField
                id="aws_iam_role"
                type="text"
                label={formFields.aws_iam_role.label}
                placeholder={formFields.aws_iam_role.placeholder}
                subtext={formFields.aws_iam_role.help}
                value={source.aws_iam_role}
                onChangeHandler={(value: string) => {
                  setSourceField("aws_iam_role", value);
                }}
                required={formFields.aws_iam_role.is_required}
                disabled={sourceTest.status === "processing"}
              />
            </>
          )}
          {selectedVendor.uses_staging_bucket && (
            <div className="h-px w-full bg-gray-200"></div>
          )}
          <div className="space-y-4">
            {selectedVendor.uses_staging_bucket && (
              <>
                <label className="block text-sm font-medium text-gray-700">
                  Enter the staging bucket credentials
                </label>
                <div className="mt-1">
                  <p className="mt-1 text-sm text-gray-500">
                    {
                      "For some destinations without built in data staging areas, a staging bucket must be provided for efficent data loading. For assistance, "
                    }
                    <a
                      href="https://docs.prequel.co/docs/s3-staging-bucket"
                      target="_blank"
                      rel="noreferrer"
                      className="font-medium text-primary-600 hover:text-primary-500"
                    >
                      view our documentation on staging buckets.
                    </a>
                  </p>
                </div>
              </>
            )}
            {"bucket_vendor" in formFields && (
              <FormField
                id="bucket_vendor"
                type="text"
                label={formFields.bucket_vendor.label}
                placeholder={formFields.bucket_vendor.placeholder}
                subtext={formFields.bucket_vendor.help}
                value={source.bucket_vendor}
                onChangeHandler={(value: string) => {
                  setSourceField("bucket_vendor", value);
                }}
                required={formFields.bucket_vendor.is_required}
                disabled={sourceTest.status === "processing"}
              />
            )}
            {"bucket_name" in formFields && (
              <FormField
                id="bucket_name"
                type="text"
                label={formFields.bucket_name.label}
                placeholder={formFields.bucket_name.placeholder}
                subtext={formFields.bucket_name.help}
                value={source.bucket_name}
                onChangeHandler={(value: string) => {
                  setSourceField("bucket_name", value);
                }}
                required={formFields.bucket_name.is_required}
                disabled={sourceTest.status === "processing" || isEditing}
              />
            )}
            {"bucket_region" in formFields && (
              <FormField
                id="bucket_region"
                type="text"
                label={formFields.bucket_region.label}
                placeholder={formFields.bucket_region.placeholder}
                subtext={formFields.bucket_region.help}
                value={source.bucket_region}
                onChangeHandler={(value: string) => {
                  setSourceField("bucket_region", value);
                }}
                required={formFields.bucket_region.is_required}
                disabled={sourceTest.status === "processing"}
              />
            )}
            {"bucket_access_id" in formFields && (
              <FormField
                id="bucket_access_id"
                type="text"
                label={formFields.bucket_access_id.label}
                placeholder={formFields.bucket_access_id.placeholder}
                subtext={formFields.bucket_access_id.help}
                value={source.bucket_access_id}
                onChangeHandler={(value: string) => {
                  setSourceField("bucket_access_id", value);
                }}
                required={formFields.bucket_access_id.is_required}
                disabled={sourceTest.status === "processing"}
              />
            )}
            {"bucket_secret_key" in formFields && (
              <FormField
                id="bucket_secret_key"
                type="password"
                label={formFields.bucket_secret_key.label}
                placeholder={
                  isEditing
                    ? PasswordDot.repeat(40)
                    : formFields.bucket_secret_key.placeholder
                }
                overwriteOnly={isEditing}
                subtext={formFields.bucket_secret_key.help}
                value={source.bucket_secret_key}
                onChangeHandler={(value: string) => {
                  setSourceField("bucket_secret_key", value);
                }}
                required={
                  formFields.bucket_secret_key.is_required && !isEditing
                }
                disabled={sourceTest.status === "processing"}
              />
            )}
            {"disable_ssl" in formFields && (
              <Toggle
                label={formFields.disable_ssl.label}
                enabled={source.disable_ssl ?? false}
                setEnabled={(isChecked: boolean) => {
                  setSourceField("disable_ssl", isChecked);
                }}
              />
            )}
            {"wallet" in formFields && !source.disable_ssl && (
              <FormField
                id="wallet"
                type="text"
                label={formFields.wallet.label}
                subtext={formFields.wallet.help}
                value={source.wallet}
                onChangeHandler={(value: string) => {
                  setSourceField("wallet", value);
                }}
                required={!source.disable_ssl}
                disabled={sourceTest.status === "processing"}
              />
            )}
          </div>
        </div>
        {selectedVendor.supports_ssh_tunnel && (
          <SSHTunnelForm connector={source} setField={setSourceField} />
        )}
        <TestSourceConnection
          beforeSubmitTest={validateForm}
          preparedSource={preparedSource}
          existingSource={sourceToEdit}
        />
      </form>
      <div className="flex justify-end mt-8">
        {isEditing && (
          <Button
            className="mr-3"
            type={ButtonStyle.TERTIARY}
            onClick={() => navigate(-1)}
            text="Cancel"
          />
        )}
        <Button
          type={
            !(sourceTest.status === "success")
              ? ButtonStyle.TERTIARY
              : ButtonStyle.PRIMARY
          }
          disabled={!(sourceTest.status === "success")}
          onClick={onSave}
          text="Save Source"
        />
      </div>
    </div>
  );
};

export default SourceForm;
