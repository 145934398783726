import React from "react";
import { format, parseJSON } from "date-fns";

type TimestampProps = {
  timestamp: string;
};
const Timestamp = ({ timestamp }: TimestampProps) => {
  return (
    <span className="truncate font-mono text-xs text-gray-600 bg-gray-100 px-2 py-0.5 rounded-sm">
      {format(parseJSON(timestamp), "yyyy-MM-dd HH:mm:ss.SSS z")}
    </span>
  );
};

export default Timestamp;
