import React, { useEffect, useState } from "react";
import { Destination, ModelConfig } from "@prequel/react";
import { Checkbox } from "@prequel-internal/react-components";

import { useTypedDispatch, useTypedSelector } from "../../../../store";
import { ALL_CURRENT_FUTURE_MODELS } from "../../../../store/models";
import {
  fetchProducts,
  selectProducts,
} from "../../../../store/products/products.duck";
import {
  fetchModels,
  selectModels,
} from "../../../../store/models/models.duck";

type ProductsAndModelsProps = {
  destination: Destination;
  setDestinationField: (
    key: keyof Destination,
    value: string | boolean | string[]
  ) => void;
  modelsError?: string;
  disabled: boolean;
  isEditing: boolean;
};
const ProductsAndModels = ({
  destination,
  setDestinationField,
  modelsError,
  disabled,
  isEditing,
}: ProductsAndModelsProps) => {
  const dispatch = useTypedDispatch();
  const [availableModels, setAvailableModels] = useState<ModelConfig[]>();
  // enabledModels is only for keeping track of previous state when all_models_present_future is unselected
  const [enabledModels, setEnabledModels] = useState<string[]>();
  const products = useTypedSelector(selectProducts);
  const models = useTypedSelector(selectModels);
  const allFutureModels =
    JSON.stringify(destination.enabled_models) ===
    JSON.stringify(ALL_CURRENT_FUTURE_MODELS);

  useEffect(() => {
    dispatch(fetchModels());
    dispatch(fetchProducts());
  }, [dispatch]);

  useEffect(() => {
    // only set available models with all models in the case there are no product configs
    // products are undefined if the fetch hasn't returned yet
    if (models && products !== undefined) {
      setAvailableModels(models);
      // initially enable all models if not in edit mode, we expect enabled models to be set if we are editing
      if (!isEditing) {
        const enabled = models.map(({ model_name }) => model_name);
        setEnabledModels(enabled);
        setDestinationField("enabled_models", ALL_CURRENT_FUTURE_MODELS);
      }
    }
  }, [models]);

  useEffect(() => {
    // if selectedProducts change, update the available models list
    if (products) {
      let modelNames: string[] = [];
      products?.map((cfg) => {
        // If the product is in the list, append all the models
        if (destination.products?.includes(cfg.product_name)) {
          modelNames = [...modelNames, ...cfg.models];
        }
      });

      const newAvailableModels = models?.filter(({ model_name }) =>
        modelNames.includes(model_name)
      );

      setAvailableModels(newAvailableModels);
    }
  }, [destination.products, products, models]);

  useEffect(() => {
    // if availableModels changes, reset all models to enabled if we are not in edit mode
    // if editing, we expect enabled models to be set from the destination
    if (availableModels && !isEditing) {
      setEnabledModels(availableModels.map(({ model_name }) => model_name));

      // Update enabled_models on destination in case any models were removed from available, but respect '*' selection
      if (!allFutureModels) {
        setDestinationField(
          "enabled_models",
          availableModels.map(({ model_name }) => model_name)
        );
      }
    }
  }, [availableModels]);

  const updateModels = (isEnabled: boolean, modelName: string) => {
    let updatedModels: string[] = [];
    if (isEnabled) {
      updatedModels = [...(destination.enabled_models ?? []), modelName];
    } else {
      updatedModels =
        destination.enabled_models?.filter((m) => m !== modelName) ?? [];
    }

    setDestinationField("enabled_models", updatedModels);
    setEnabledModels(updatedModels);
  };

  return (
    <>
      {availableModels && availableModels.length > 0 && (
        <>
          <label className="block text-sm font-medium text-gray-700">
            Select what models the destination will receive
          </label>
          {availableModels.map(({ model_name, description }) => (
            <Checkbox
              key={model_name}
              id={model_name}
              label={model_name}
              description={description}
              checked={
                destination.enabled_models?.includes(model_name) ||
                allFutureModels
              }
              setChecked={(isChecked) => updateModels(isChecked, model_name)}
              disabled={disabled || allFutureModels}
            />
          ))}
          <Checkbox
            key={"all_models_present_future"}
            id={"all_models_present_future"}
            label={"All current and future models"}
            description={
              "Any model added to these products in the future will be synced"
            }
            checked={allFutureModels}
            setChecked={(isChecked: boolean) =>
              isChecked
                ? setDestinationField(
                    "enabled_models",
                    ALL_CURRENT_FUTURE_MODELS
                  )
                : setDestinationField("enabled_models", enabledModels ?? [])
            }
            disabled={disabled}
          />
        </>
      )}
      {modelsError && (
        <p className="mt-1 text-xs font-medium text-red-600">{modelsError}</p>
      )}
    </>
  );
};

export default ProductsAndModels;
