import React, { useEffect } from "react";
import {
  Button,
  ButtonStyle,
  PageHeading,
  Tabs,
  checkTabIsActive,
} from "@prequel-internal/react-components";
import { NavLink, useNavigate, useRoutes } from "react-router-dom";
import { useTypedDispatch, useTypedSelector } from "../../store";
import RecipientsTable from "./RecipientsTable";
import {
  fetchRecipients,
  selectRecipients,
  fetchSourceCredentials,
  selectSourceCredentials,
} from "../../store/recipients/recipients.duck";
import { fetchOrg, selectOrg } from "../../store/org/org.duck";
import RecipientForm from "./RecipientForm";
import SourceCredentialsTable from "./SourceCredentialsTable";
import SourceCredentialForm from "./SourceCredentialForm";
import {
  fetchSourceVendors,
  fetchSources,
  selectSources,
} from "../../store/sources/sources.duck";

const RecipientsPage = () => {
  const tabs = [
    <NavLink
      key="Summary"
      to="/export/recipients"
      className={checkTabIsActive}
      replace
      end
    >
      Summary
    </NavLink>,
    <NavLink
      key="Source Credentials"
      to="/export/recipients/source-credentials"
      className={checkTabIsActive}
      replace
      end
    >
      Source Credentials
    </NavLink>,
  ];
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const recipients = useTypedSelector(selectRecipients);
  const sources = useTypedSelector(selectSources);
  const sourceCredentials = useTypedSelector(selectSourceCredentials);
  const org = useTypedSelector(selectOrg);

  useEffect(() => {
    dispatch(fetchRecipients());
    dispatch(fetchSources());
    dispatch(fetchSourceVendors());
    dispatch(fetchSourceCredentials());
    dispatch(fetchOrg());
  }, [dispatch]);

  const element = useRoutes([
    {
      path: "/",
      element: (
        <div className="max-w-6xl pb-16">
          <PageHeading title="Recipients">
            <Button
              text="Add Recipient"
              onClick={() => navigate("new")}
              type={ButtonStyle.SECONDARY}
            />
          </PageHeading>
          <Tabs tabs={tabs} />
          <RecipientsTable
            recipients={recipients}
            sourcesSchemaTenanted={org?.sources_schema_tenanted ?? false}
          />
        </div>
      ),
    },
    {
      path: "/source-credentials",
      element: (
        <div className="max-w-6xl pb-16">
          <PageHeading title="Recipients">
            <Button
              text="Add Recipient"
              onClick={() => navigate("new")}
              type={ButtonStyle.SECONDARY}
            />
          </PageHeading>
          <Tabs tabs={tabs} />
          <SourceCredentialsTable
            sources={sources}
            recipients={recipients}
            sourceCredentials={sourceCredentials}
          />
        </div>
      ),
    },
    {
      path: "new",
      element: (
        <div className="max-w-lg">
          <PageHeading title="Add Recipient" onGoBack={() => navigate(-1)} />
          <RecipientForm />
        </div>
      ),
    },
    {
      path: "source-credentials/new",
      element: (
        <div className="max-w-lg">
          <PageHeading
            title="Add Source Credential"
            onGoBack={() => navigate(-1)}
          />
          <SourceCredentialForm sources={sources} recipients={recipients} />
        </div>
      ),
    },
  ]);

  return <div className="flex flex-col py-4 pb-4 px-7">{element}</div>;
};

export default RecipientsPage;
