import React from "react";
import { NavLink } from "react-router-dom";

type NavigationItemProps = {
  to: string;
  title: string;
  isExternal?: boolean;
};
const NavigationItem = ({
  to,
  title,
  isExternal = false,
}: NavigationItemProps) => {
  const checkActive = ({ isActive }: { isActive: boolean }) =>
    "flex items-center px-3 py-1 text-sm rounded-sm " +
    (isActive
      ? "bg-slate-100 text-slate-900 font-medium"
      : "text-slate-600 font-normal hover:bg-slate-100 hover:text-slate-900");

  return isExternal ? (
    <a
      className={checkActive({ isActive: false })}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      aria-label=""
    >
      <svg
        className="w-4 mr-2 text-slate-400"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <line x1="7" y1="17" x2="17" y2="7"></line>
        <polyline points="7 7 17 7 17 17"></polyline>
      </svg>

      {title}
    </a>
  ) : (
    <NavLink className={checkActive} to={to}>
      {title}
    </NavLink>
  );
};

export default NavigationItem;
