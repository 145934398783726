import React, { useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";

import { useTypedDispatch, useTypedSelector } from "../../../../../store";
import { fetchOrg, selectOrg } from "../../../../../store/org/org.duck";
import { fetchUser, selectUser } from "../../../../../store/user/user.duck";

import { ReactComponent as DownloadIcon } from "../../../../../assets/icons/arrow-down-tray.svg";

type DownloadMenuProps = {
  menuItems: Array<{ text: string; onClick: () => void }>;
};

const DownloadMenu = ({ menuItems }: DownloadMenuProps) => {
  const dispatch = useTypedDispatch();
  const org = useTypedSelector(selectOrg);
  const user = useTypedSelector(selectUser);

  useEffect(() => {
    dispatch(fetchOrg());
    dispatch(fetchUser());
  }, [dispatch]);

  return (
    <Menu as="div" className="h-full w-full">
      <Menu.Button className="p-1 mt-1 mb-1 rounded-md hover:bg-neutral-200 focus:ring-emerald-600 focus:ring-2 focus:ring-offset-2">
        <span className="">
          <DownloadIcon className="stroke-1.5 h-5 w-5" />
        </span>
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute bg-white z-10 mt-2 w-60 right-4 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1 w-full">
            {menuItems.map((menuItem, key) => (
              <Menu.Item key={key}>
                {({ active }) => (
                  <button
                    onClick={menuItem.onClick}
                    className={`${
                      active ? "bg-slate-100 text-slate-900" : "text-slate-700"
                    }
                  flex px-4 py-2 text-sm w-full
                `}
                  >
                    {menuItem.text}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
export default DownloadMenu;
