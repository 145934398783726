import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, put, takeEvery } from "redux-saga/effects";

import { AppError } from "../../axios";
import { User } from ".";
import UserService from "./user.service";
import { RootState, createWorkerSaga } from "..";

// Slice state
type UserState = {
  user: User | undefined;
};
const initialState: UserState = {
  user: undefined,
};

// Action Reducers (Case Reducers)
const fetchUserReducer: CaseReducer<UserState, PayloadAction<void>> = (state) =>
  state;

const fetchUserSuccessReducer: CaseReducer<UserState, PayloadAction<User>> = (
  state,
  action
) => {
  state.user = action.payload;
};

const fetchUserFailureReducer: CaseReducer<
  UserState,
  PayloadAction<AppError>
> = (state) => state;

function* watchFetchUser() {
  yield takeEvery(
    fetchUser.type,
    createWorkerSaga(
      fetchUser,
      fetchUserSuccess,
      fetchUserFailure,
      UserService.getUser
    )
  );
}

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchUser: fetchUserReducer,
    fetchUserSuccess: fetchUserSuccessReducer,
    fetchUserFailure: fetchUserFailureReducer,
  },
});

export const { fetchUser, fetchUserSuccess, fetchUserFailure } =
  userSlice.actions;

export const selectUser = ({ user }: RootState) => user.user;

export function* userSaga() {
  yield all([watchFetchUser()]);
}
export default userSlice.reducer;
