import React, { useEffect } from "react";
import Timestamp from "../../../components/Timestamp";
import {
  Table,
  TableRow,
  Badge,
  BadgeColor,
} from "@prequel-internal/react-components";
import { useNavigate } from "react-router-dom";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import { fetchModels, selectModels } from "../../../store/models/models.duck";

const ModelsTable = () => {
  const dispatch = useTypedDispatch();
  const models = useTypedSelector(selectModels);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchModels());
  }, [dispatch]);

  const rows = [...(models || [])].map(
    ({ id, model_name, description, created_at }) => ({
      id: id,
      cells: [
        {
          value: <Badge color={BadgeColor.GRAY} text={model_name} />,
        },
        {
          value: (
            <>
              {description === "" ? (
                <span className="flex-grow text-gray-300 italic">
                  undefined
                </span>
              ) : (
                description
              )}
            </>
          ),
        },
        { value: <Timestamp timestamp={created_at} /> },
      ],
    })
  );

  const columns = ["Model Name", "Description", "Created At"];

  const onRowClick = (row: TableRow) => navigate(`${row.id}`);

  return (
    <Table
      columns={columns}
      rows={rows}
      footer={models ? `${models.length} models` : ""}
      onRowClick={onRowClick}
    />
  );
};

export default ModelsTable;
