import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, takeEvery } from "redux-saga/effects";
import { createWorkerSaga, RootState } from "..";
import { AppError } from "../../axios";
import SSHService from "./ssh.service";

type SSHState = {
  sshPublicKey: string | undefined;
};
const initialState: SSHState = {
  sshPublicKey: undefined,
};
const fetchSSHPublicKeyReducer: CaseReducer<SSHState, PayloadAction<void>> = (
  state
) => state;

const fetchSSHPublicKeySuccessReducer: CaseReducer<
  SSHState,
  PayloadAction<string>
> = (state, action) => {
  state.sshPublicKey = action.payload;
};

const fetchSSHPublicKeyFailureReducer: CaseReducer<
  SSHState,
  PayloadAction<AppError>
> = (state) => state;

const clearSSHPublicKeyReducer: CaseReducer<SSHState, PayloadAction<void>> = (
  state: SSHState
) => {
  state.sshPublicKey = undefined;
};

function* watchFetchSSHPublicKey() {
  yield takeEvery(
    fetchSSHPublicKey.type,
    createWorkerSaga(
      fetchSSHPublicKey,
      fetchSSHPublicKeySuccess,
      fetchSSHPublicKeyFailure,
      SSHService.getPublicKey
    )
  );
}

const sshSlice = createSlice({
  name: "ssh",
  initialState,
  reducers: {
    fetchSSHPublicKey: fetchSSHPublicKeyReducer,
    fetchSSHPublicKeySuccess: fetchSSHPublicKeySuccessReducer,
    fetchSSHPublicKeyFailure: fetchSSHPublicKeyFailureReducer,
    clearSSHPublicKey: clearSSHPublicKeyReducer,
  },
});

export const {
  fetchSSHPublicKey,
  fetchSSHPublicKeySuccess,
  fetchSSHPublicKeyFailure,
  clearSSHPublicKey,
} = sshSlice.actions;

export const selectSSHPublicKey = ({ ssh }: RootState) => ssh.sshPublicKey;

export function* sshSaga() {
  yield all([watchFetchSSHPublicKey()]);
}

export default sshSlice.reducer;
