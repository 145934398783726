import React, { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  DropdownListItem,
  Header,
} from "@prequel-internal/react-components";

import { useTypedDispatch, useTypedSelector } from "../../store";

import Navigation from "../Navigation";
import Lightbox from "./Lightbox";
import ScrollToTop from "./ScrollToTop";
import {
  selectEnvironment,
  updateEnvironment,
} from "../../store/auth/auth.duck";
import { dropdownEnvironments, Environment } from "../../store/auth";

type LayoutProps = {
  children: React.ReactNode;
};
const Layout = ({ children }: LayoutProps) => {
  const scrollElementRef = useRef<HTMLElement>(null);
  const [currentEnvironment, setCurrentEnvironment] = useState<
    DropdownListItem<Environment>
  >(dropdownEnvironments[0]);
  const environment = useTypedSelector(selectEnvironment);

  useEffect(() => {
    const e = dropdownEnvironments.find(({ key }) => key === environment);
    e && setCurrentEnvironment(e);
  }, [environment]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Navigation />
      <div className="flex flex-col flex-1 h-full overflow-hidden bg-white">
        <main ref={scrollElementRef} className="flex h-full overflow-auto">
          <ScrollToTop scrollElementRef={scrollElementRef} />
          <div className="w-full h-full">{children}</div>
        </main>
      </div>
      <Lightbox />
    </div>
  );
};

export default Layout;
