import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, put, takeEvery } from "redux-saga/effects";
import { ExistingDestination, Transfer } from "@prequel/react";

import { AppError } from "../../axios";
import TransfersService from "./transfers.service";
import {
  RootState,
  WithRedirect,
  createRedirectSaga,
  createWorkerSaga,
} from "..";

import { createToast } from "../toasts/toasts.duck";

// Slice state
type TransfersState = {
  transfers: { [id: Transfer["id"]]: Transfer[] };
  isLoadingTransfers: boolean;
  isPostingTransfer: boolean;
  isCancelingTransfer: boolean;
};
const initialState: TransfersState = {
  transfers: {},
  isLoadingTransfers: false,
  isPostingTransfer: false,
  isCancelingTransfer: false,
};

// Action Reducers (Case Reducers)
const fetchTransfersReducer: CaseReducer<
  TransfersState,
  PayloadAction<ExistingDestination["id"]>
> = (state) => {
  state.isLoadingTransfers = true;
};

const fetchTransfersSuccessReducer: CaseReducer<
  TransfersState,
  PayloadAction<{
    destinationId: ExistingDestination["id"] | undefined;
    transfers: Transfer[];
  }>
> = (state, action) => {
  state.isLoadingTransfers = false;
  if (action.payload.destinationId) {
    state.transfers[action.payload.destinationId] = action.payload.transfers;
  }
};

const fetchTransfersFailureReducer: CaseReducer<
  TransfersState,
  PayloadAction<AppError>
> = (state) => {
  state.isLoadingTransfers = false;
};

const createTransferReducer: CaseReducer<
  TransfersState,
  PayloadAction<{
    destinationId: ExistingDestination["id"];
    fullRefresh: boolean;
    models?: string[];
  }>
> = (state) => {
  state.isPostingTransfer = true;
};

const createTransferSuccessReducer: CaseReducer<
  TransfersState,
  PayloadAction<{ destinationId: ExistingDestination["id"] }>
> = (state) => {
  state.isPostingTransfer = false;
};

const createTransferFailureReducer: CaseReducer<
  TransfersState,
  PayloadAction<AppError>
> = (state) => {
  state.isPostingTransfer = false;
};

const cancelExportTransferReducer: CaseReducer<
  TransfersState,
  PayloadAction<WithRedirect<{ transferId: Transfer["id"] }>>
> = (state) => {
  state.isCancelingTransfer = true;
};

const cancelExportTransferSuccessReducer: CaseReducer<
  TransfersState,
  PayloadAction<WithRedirect<{}>>
> = (state) => {
  state.isCancelingTransfer = false;
};

const cancelExportTransferFailureReducer: CaseReducer<
  TransfersState,
  PayloadAction<AppError>
> = (state) => {
  state.isCancelingTransfer = false;
};

function* watchFetchTransfers() {
  yield takeEvery(
    fetchTransfers.type,
    createWorkerSaga(
      fetchTransfers,
      fetchTransfersSuccess,
      fetchTransfersFailure,
      TransfersService.getTransfers
    )
  );
}

function* watchCreateTransfer() {
  yield takeEvery(
    createTransfer.type,
    createWorkerSaga(
      createTransfer,
      createTransferSuccess,
      createTransferFailure,
      TransfersService.postTransfer
    )
  );
}

function* watchCancelExportTransfer() {
  yield takeEvery(
    cancelExportTransfer.type,
    createWorkerSaga(
      cancelExportTransfer,
      cancelExportTransferSuccess,
      cancelExportTransferFailure,
      TransfersService.cancelExportTransfer
    )
  );
}

function* watchCancelExportTransferSuccessOrFailure() {
  yield takeEvery(
    [cancelExportTransferFailure.type, cancelExportTransferSuccess.type],
    function* (
      action: PayloadAction<{
        destinationId: ExistingDestination["id"];
      }>
    ) {
      yield put(fetchTransfers(action.payload.destinationId));
    }
  );
  yield takeEvery(
    [cancelExportTransferFailure.type, cancelExportTransferSuccess.type],
    createRedirectSaga()
  );
}

function* watchCreateTransferSuccess() {
  yield takeEvery(createTransferSuccess.type, function* () {
    yield put(
      createToast({
        toast: {
          id: new Date().getTime().toString(),
          kind: "SUCCESS",
          message: "Transfer enqueued successfully!",
        },
      })
    );
  });
}

function* watchCreateTransferSuccessOrFailure() {
  yield takeEvery(
    [createTransferFailure.type, createTransferSuccess.type],
    function* (
      action: PayloadAction<{
        destinationId: ExistingDestination["id"];
      }>
    ) {
      yield put(fetchTransfers(action.payload.destinationId));
    }
  );
}

const transfersSlice = createSlice({
  name: "transfers",
  initialState,
  reducers: {
    cancelExportTransfer: cancelExportTransferReducer,
    cancelExportTransferSuccess: cancelExportTransferSuccessReducer,
    cancelExportTransferFailure: cancelExportTransferFailureReducer,
    fetchTransfers: fetchTransfersReducer,
    fetchTransfersSuccess: fetchTransfersSuccessReducer,
    fetchTransfersFailure: fetchTransfersFailureReducer,
    createTransfer: createTransferReducer,
    createTransferSuccess: createTransferSuccessReducer,
    createTransferFailure: createTransferFailureReducer,
  },
});

export const {
  cancelExportTransfer,
  cancelExportTransferSuccess,
  cancelExportTransferFailure,
  fetchTransfers,
  fetchTransfersSuccess,
  fetchTransfersFailure,
  createTransfer,
  createTransferSuccess,
  createTransferFailure,
} = transfersSlice.actions;

export const selectTransfers = (destinationId: string | undefined) => {
  return ({ transfers }: RootState) => {
    return destinationId ? transfers.transfers[destinationId] : undefined;
  };
};
export const selectIsPostingTransfer = ({ transfers }: RootState) =>
  transfers.isPostingTransfer;
export const selectIsCancelingTransfer = ({ transfers }: RootState) =>
  transfers.isCancelingTransfer;
export const selectIsLoadingTransfers = ({ transfers }: RootState) =>
  transfers.isLoadingTransfers;

export function* transfersSaga() {
  yield all([
    watchFetchTransfers(),
    watchCreateTransfer(),
    watchCreateTransferSuccess(),
    watchCreateTransferSuccessOrFailure(),
    watchCancelExportTransfer(),
    watchCancelExportTransferSuccessOrFailure(),
  ]);
}
export default transfersSlice.reducer;
