import React, { useEffect, useState } from "react";
import { Dropdown, DropdownListItem } from "@prequel-internal/react-components";
import { Destination, FormField } from "@prequel/react";
import { Link } from "react-router-dom";

import InputWrapper from "../../WrapperSelector/InputWrapper";
import { useTypedDispatch, useTypedSelector } from "../../../../../store";
import {
  fetchRecipients,
  selectRecipients,
} from "../../../../../store/recipients/recipients.duck";
import { ReactComponent as AlertIcon } from "../../../../../assets/icons/alert-circle.svg";

type InternalWrapperProps = {
  field: FormField;
  destination: Destination;
  setDestinationField: (
    key: keyof Destination,
    value: string | boolean | string[]
  ) => void;
  disabled: boolean;
  isEditing: boolean;
};
const InternalWrapper = ({
  field,
  destination,
  setDestinationField,
  disabled,
  isEditing,
}: InternalWrapperProps) => {
  const [recipientOptions, setRecipientOptions] =
    useState<DropdownListItem<string>[]>();

  const dispatch = useTypedDispatch();
  const recipients = useTypedSelector(selectRecipients);

  useEffect(() => {
    dispatch(fetchRecipients());
  }, [dispatch]);

  useEffect(() => {
    if (recipients) {
      const options = recipients.map((recipient) => ({
        key: recipient.id,
        text: (
          <span className="flex items-center">
            <span className="mr-1">{recipient.name}</span>
            <span className="text-xs text-gray-500 truncate">
              ({recipient.id_in_provider_system ?? recipient.schema})
            </span>
          </span>
        ),
        products: recipient.products,
      }));

      setRecipientOptions(options);
    }
  }, [recipients]);

  if (field.name === "name" && field.form_element === "input") {
    return (
      <InputWrapper
        field={field}
        destination={destination}
        setDestinationField={setDestinationField}
        disabled={disabled}
        isEditing={isEditing}
      />
    );
  }

  if (field.name === "recipient_id") {
    const selectedRecipient = recipientOptions?.find(
      ({ key }) => key === destination.recipient_id
    );

    return (
      <>
        {recipientOptions && recipientOptions.length ? (
          <>
            <Dropdown
              label={field.label}
              items={recipientOptions ?? []}
              selectedItem={selectedRecipient}
              setSelectedItem={({ key }) =>
                setDestinationField("recipient_id", key)
              }
              disabled={disabled || isEditing}
            />
            <p
              className="mt-2 text-xs text-gray-400 whitespace-pre-line"
              id="description"
            >
              {"Recipient name (id_in_provider_system)"}
            </p>
          </>
        ) : (
          <div className="flex-col bg-gray-50 border border-gray-200 text-gray-500 rounded p-3 text-sm">
            <div className="flex">
              <AlertIcon
                className="h-5 w-5 text-emerald-600"
                aria-hidden="true"
              />
              <div className="ml-1">
                <p className="text-sm text-gray-900">
                  No configured recipients.
                </p>
                <p className="text-xs">
                  <Link
                    className="text-emerald-600 hover:text-emerald-800"
                    to="/export/recipients/new"
                    target="_blank"
                  >
                    Create a recipient here
                  </Link>{" "}
                  to add your first.
                </p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  // Skip any field that we haven't special-cased
  return <></>;
};

export default InternalWrapper;
