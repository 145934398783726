import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, put, takeEvery } from "redux-saga/effects";

import { AppError } from "../../axios";
import { Organization } from ".";
import OrgService from "./org.service";
import { RootState, createWorkerSaga } from "..";

// Slice state
type OrgState = {
  org: Organization | undefined;
};
const initialState: OrgState = {
  org: undefined,
};

// Action Reducers (Case Reducers)
const fetchOrgReducer: CaseReducer<OrgState, PayloadAction<void>> = (state) =>
  state;

const fetchOrgSuccessReducer: CaseReducer<
  OrgState,
  PayloadAction<Organization>
> = (state, action) => {
  state.org = action.payload;
};

const fetchOrgFailureReducer: CaseReducer<OrgState, PayloadAction<AppError>> = (
  state
) => state;

function* watchFetchOrg() {
  yield takeEvery(
    fetchOrg.type,
    createWorkerSaga(
      fetchOrg,
      fetchOrgSuccess,
      fetchOrgFailure,
      OrgService.getOrg
    )
  );
}

const orgSlice = createSlice({
  name: "org",
  initialState,
  reducers: {
    fetchOrg: fetchOrgReducer,
    fetchOrgSuccess: fetchOrgSuccessReducer,
    fetchOrgFailure: fetchOrgFailureReducer,
  },
});

export const { fetchOrg, fetchOrgSuccess, fetchOrgFailure } = orgSlice.actions;

export const selectOrg = ({ org }: RootState) => org.org;

export function* orgSaga() {
  yield all([watchFetchOrg()]);
}
export default orgSlice.reducer;
