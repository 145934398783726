import React, { useEffect, useState } from "react";
import { FormField, Destination } from "@prequel/react";
import { CopyToClipboard } from "@prequel-internal/react-components";
import SSHPublicKey from "./SSHPublicKey";

type ConstWrapperProps = {
  field: FormField;
  destination: Destination;
  setDestinationField: (
    key: keyof Destination,
    value: string | boolean | string[]
  ) => void;
  isEditing: boolean;
};
const ConstWrapper = ({
  field,
  destination,
  setDestinationField,
  isEditing,
}: ConstWrapperProps) => {
  if (!field.const) {
    return <></>;
  }

  if (field.name === "ssh_public_key" || field.name === "public_key") {
    return (
      <SSHPublicKey
        field={field}
        destination={destination}
        setDestinationField={setDestinationField}
        isEditing={isEditing}
      />
    );
  }

  return (
    <>
      <label className="block text-sm font-medium text-gray-700">
        {field.label}
      </label>
      {typeof field.const === "object" ? (
        <div className="w-full shadow-sm inline-flex rounded-md pl-3 pr-1 py-2 mt-1 text-gray-500 text-sm bg-gray-50 border border-gray-300 font-mono overflow-auto	resize-y ">
          <div className="w-full h-14 mr-3 whitespace-pre-wrap">
            {JSON.stringify(field.const, null, 2)}
          </div>
          <CopyToClipboard
            textToCopy={JSON.stringify(field.const, null, 2)}
            className="sticky top-0 self-start"
          />
        </div>
      ) : (
        <div className="w-full shadow-sm inline-flex rounded-md pl-3 pr-1 py-2 mt-1 text-gray-500 text-sm bg-gray-50 border border-gray-300 font-mono">
          <div className="w-full truncate mr-3">{field.const}</div>
          <CopyToClipboard textToCopy={field.const} />
        </div>
      )}
      {field.description && (
        <p
          className="mt-2 text-xs text-gray-400 whitespace-pre-line"
          id="description"
        >
          {field.description}
        </p>
      )}
    </>
  );
};

export default ConstWrapper;
