import React, { useEffect } from "react";
import {
  Button,
  ButtonStyle,
  Spinner,
} from "@prequel-internal/react-components";

import { ReactComponent as AlertCircleIcon } from "../../../assets/icons/alert-circle.svg";
import { ReactComponent as AlertTriangleIcon } from "../../../assets/icons/alert-triangle.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as EllipsisIcon } from "../../../assets/icons/ellipsis.svg";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  resetSourceCredentialTest,
  selectSourceCredentialTest,
  testSourceCredential,
} from "../../../store/recipients/recipients.duck";
import { SourceCredential } from "../../../store/recipients";

type TestSourceCredentialConnectionProps = {
  beforeSubmitTest?: () => boolean;
  sourceCredential: SourceCredential;
};
const TestSourceCredentialConnection = ({
  beforeSubmitTest = () => true,
  sourceCredential,
}: TestSourceCredentialConnectionProps) => {
  const dispatch = useTypedDispatch();
  const sourceCredentialTest = useTypedSelector(selectSourceCredentialTest);

  // Cleanup test state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetSourceCredentialTest());
    };
  }, []);

  const onTestSourceCredential = () => {
    if (!beforeSubmitTest()) {
      return;
    }

    dispatch(testSourceCredential(sourceCredential));
  };

  return (
    <div className="relative pt-4">
      <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-primary-500 focus-within:ring-1 focus-within:ring-primary-500">
        <div className="p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <div
                className={`mx-auto flex items-center justify-center h-10 w-10 rounded-full ${
                  sourceCredentialTest.status === "success"
                    ? "bg-emerald-100"
                    : sourceCredentialTest.status === "error"
                    ? "bg-red-100"
                    : sourceCredentialTest.status === "processing"
                    ? "bg-gray-100"
                    : "bg-gray-100"
                }`}
              >
                {sourceCredentialTest.status === "success" && (
                  <CheckIcon
                    className="h-5 w-5 text-emerald-600"
                    aria-hidden="true"
                  />
                )}
                {sourceCredentialTest.status === "error" && (
                  <AlertCircleIcon
                    className="h-5 w-5 text-red-600"
                    aria-hidden="true"
                  />
                )}
                {sourceCredentialTest.status === "processing" && (
                  <EllipsisIcon
                    className="h-5 w-5 text-gray-600"
                    aria-hidden="true"
                  />
                )}
                {!sourceCredentialTest.status && (
                  <AlertTriangleIcon
                    className="h-5 w-5 text-gray-600"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
            <div className="ml-3 mt-1">
              <h3 className="text-lg font-medium text-gray-800">
                {sourceCredentialTest.status === "success" &&
                  "Connection successful!"}
                {sourceCredentialTest.status === "error" &&
                  "Error connecting to source."}
                {sourceCredentialTest.status === "processing" &&
                  "Testing connection..."}
                {!sourceCredentialTest.status &&
                  "Test credentials before saving."}
              </h3>
              <div className="mt-2 mb-2 text-sm text-gray-700">
                <p>
                  {sourceCredentialTest.status === "success" &&
                    "Connection was successfully established to the source.  The credentials can now be saved."}
                  {sourceCredentialTest.status === "error" && (
                    <>
                      <span className="block mb-4">
                        Try updating the credentials and try again.
                      </span>
                      <code className="text-xs">
                        {sourceCredentialTest.message}
                      </code>
                    </>
                  )}
                  {sourceCredentialTest.status === "processing" && ""}
                  {!sourceCredentialTest.status && ""}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div aria-hidden="true">
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-9" />
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 inset-x-px">
          <div className="border-t border-gray-200 px-2 py-2 flex justify-between items-center space-x-3 sm:px-3">
            <div className="flex">
              <div className="flex-shrink-0">
                <Button
                  onClick={onTestSourceCredential}
                  type={ButtonStyle.PRIMARY}
                  disabled={sourceCredentialTest.status === "processing"}
                  text={
                    sourceCredentialTest.status === "processing" ? (
                      <div className="flex">
                        <Spinner.Inline className="mr-3 text-white" />
                        Testing Connection...
                      </div>
                    ) : (
                      "Test Connection"
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestSourceCredentialConnection;
