import React from "react";
import { useRoutes, NavLink } from "react-router-dom";
import {
  Badge,
  BadgeColor,
  PageHeading,
  Tabs,
  checkTabIsActive,
  DescriptionList,
  Spinner,
} from "@prequel-internal/react-components";

import { useTypedSelector } from "../../store";
import { selectOrg } from "../../store/org/org.duck";

const SettingsPage = () => {
  // the sidebar is always rendered & dispatches fetchOrg and fetchUser
  const org = useTypedSelector(selectOrg);

  const tabs = [
    <NavLink key="org" to="#" className={checkTabIsActive}>
      Organization
    </NavLink>,
  ];

  // Still loading
  if (org === undefined) {
    return <Spinner />;
  }

  const element = useRoutes([
    {
      path: "*",
      element: (
        <div className="max-w-6xl pb-16">
          <PageHeading title={"Settings"} />
          <Tabs tabs={tabs} />
          <div className="mt-2 divide-y divide-gray-200">
            <DescriptionList>
              <DescriptionList.Item label="Name" value={org?.name} />
              <DescriptionList.Item label="ID" value={org?.id} />
              <DescriptionList.Item
                label="Users"
                value={org?.app_users.map((p) => (
                  <Badge
                    key={p}
                    className="mr-1"
                    text={p}
                    color={BadgeColor.GRAY}
                  />
                ))}
              />
              <DescriptionList.Item
                label="Default sync frequency (minutes)"
                value={org.default_frequency_minutes}
              />
              <DescriptionList.Item
                label="Fastest allowed sync frequency (minutes)"
                value={org.fastest_allowed_frequency_minutes}
              />
              <DescriptionList.Item
                label="Tenancy"
                value={
                  org.sources_schema_tenanted ? "Schema-based" : "Column-based"
                }
              />
              <DescriptionList.Item
                label="Single Sign-On (SSO)"
                value={org.sso_enabled ? "Enabled" : "Disabled"}
              />
              {org?.aws_iam_role_id !== undefined && (
                <DescriptionList.Item
                  label="AWS IAM Role ID"
                  value={org.aws_iam_role_id}
                />
              )}
              {org?.aws_iam_role_name !== undefined && (
                <DescriptionList.Item
                  label="AWS IAM Role Name"
                  value={org.aws_iam_role_name}
                />
              )}
              {org?.gcp_iam_role_id !== undefined && (
                <DescriptionList.Item
                  label="GCP IAM Role ID"
                  value={org.gcp_iam_role_id}
                />
              )}
              {org?.gcp_iam_role_name !== undefined && (
                <DescriptionList.Item
                  label="GCP IAM Role Name"
                  value={org.gcp_iam_role_name}
                />
              )}
            </DescriptionList>
          </div>
        </div>
      ),
    },
  ]);

  return <div className="flex flex-col py-4 px-7">{element}</div>;
};

export default SettingsPage;
