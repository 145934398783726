import {
  DestinationType,
  PreparedDestination,
  ExistingDestination,
} from "@prequel/react";
import axios, {
  ApiRoutes,
  ApiResponse,
  AppError,
  ErrorResponse,
  ID_PLACEHOLDER,
  RequestStatus,
  API_VERSION_HEADER,
  API_VERSION_2023_12_01,
  MAX_PAGE_SIZE,
} from "../../axios";

import { WithRedirect } from "..";
import {
  ExistingDestinationPayload,
  TestDestinationResponse,
  TestDestinationResult,
} from ".";

const getDestinations: () => Promise<ExistingDestination[]> = () => {
  return axios
    .get(ApiRoutes.DESTINATIONS, {
      headers: {
        [API_VERSION_HEADER]: API_VERSION_2023_12_01,
      },
      params: {
        page_size: MAX_PAGE_SIZE,
      },
    })
    .then((response: ApiResponse<{ destinations: ExistingDestination[] }>) => {
      const destinations: ExistingDestination[] =
        response.data.data.destinations.map((s) => ({
          ...s,
          type: DestinationType.ExistingDestination,
        }));
      return destinations;
    })
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message || "Failed to fetch destinations.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const testNewDestination: (
  d: PreparedDestination
) => Promise<TestDestinationResult> = (destination) => {
  return axios
    .post(
      ApiRoutes.TEST_DESTINATION,
      { destination },
      {
        headers: {
          [API_VERSION_HEADER]: API_VERSION_2023_12_01,
        },
      }
    )
    .then((response: ApiResponse<TestDestinationResponse>) => ({
      status: response.data.data?.status,
      message: response.data.message,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message ||
            "Destination connection test failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const testExistingDestination: (
  p: ExistingDestinationPayload
) => Promise<TestDestinationResult> = ({ destinationId, fields }) => {
  const url = ApiRoutes.TEST_EXISTING_DESTINATION.replace(
    ID_PLACEHOLDER,
    destinationId
  );
  return axios
    .post(url, fields ? { destination: fields } : undefined, {
      headers: {
        [API_VERSION_HEADER]: API_VERSION_2023_12_01,
      },
    })
    .then((response: ApiResponse<TestDestinationResponse>) => ({
      status: response.data.data?.status,
      message: response.data.message,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message ||
            "Destination connection test failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postDestination: (
  p: WithRedirect<{ destination: PreparedDestination }>
) => Promise<WithRedirect<{ destination: ExistingDestination }>> = ({
  destination,
  redirect,
}) => {
  return axios
    .post(
      ApiRoutes.DESTINATIONS,
      { destination },
      {
        headers: {
          [API_VERSION_HEADER]: API_VERSION_2023_12_01,
        },
      }
    )
    .then((response: ApiResponse<{ destination: ExistingDestination }>) => ({
      destination: response.data.data.destination,
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Destination creation failed.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const transferDestination: ({
  destinationId,
  fullRefresh,
}: {
  destinationId: ExistingDestination["id"];
  fullRefresh: boolean;
}) => Promise<void> = ({ destinationId, fullRefresh }) => {
  return (
    axios
      .post(`${ApiRoutes.DESTINATIONS}/${destinationId}${ApiRoutes.TRANSFER}`, {
        full_refresh: fullRefresh,
      })
      // TODO: capture the transfer_id and trigger a toast to indicate a successful transfer was enqueued
      .then((response: ApiResponse<void>) => response.data.data)
      .catch((reason: ErrorResponse) => {
        const e: AppError = {
          error: {
            message:
              reason.response?.data?.message || "Transfer failed to enqueue.",
            suppressGlobalNotification: false,
            statusCode: reason.response?.status,
          },
        };
        throw e;
      })
  );
};

const patchDestination: (
  p: WithRedirect<ExistingDestinationPayload>
) => Promise<WithRedirect<{ destination: ExistingDestination }>> = ({
  destinationId,
  fields,
  redirect,
}) => {
  return axios
    .patch(
      `${ApiRoutes.DESTINATIONS}/${destinationId}`,
      {
        destination: fields,
      },
      {
        headers: {
          [API_VERSION_HEADER]: API_VERSION_2023_12_01,
        },
      }
    )
    .then((response: ApiResponse<{ destination: ExistingDestination }>) => ({
      destination: response.data.data.destination,
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to update destination.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const deleteDestination: ({
  destinationId,
  redirect,
}: WithRedirect<{
  destinationId: ExistingDestination["id"];
}>) => Promise<WithRedirect<{}>> = ({ destinationId, redirect }) => {
  return axios
    .delete(`${ApiRoutes.DESTINATIONS}/${destinationId}`)
    .then((response: ApiResponse<{}>) => ({ redirect }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to delete destination.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const DestinationsService = {
  getDestinations,
  testNewDestination,
  testExistingDestination,
  postDestination,
  transferDestination,
  patchDestination,
  deleteDestination,
};
export default DestinationsService;
