import axios, { ApiRoutes, ApiResponse, ErrorResponse } from "../../axios";

import { User } from ".";

const getUser: () => Promise<User> = () => {
  return axios
    .get(ApiRoutes.USER)
    .then((response: ApiResponse<User>) => {
      return response.data.data;
    })
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message || "Failed to fetch user details.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const UserService = {
  getUser,
};
export default UserService;
