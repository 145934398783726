import React, { useEffect } from "react";
import {
  Button,
  ButtonStyle,
  Spinner,
} from "@prequel-internal/react-components";
import {
  DestinationType,
  ExistingDestination,
  PreparedDestination,
  computeChangedFields,
} from "@prequel/react";

import { ReactComponent as AlertCircleIcon } from "../../../assets/icons/alert-circle.svg";
import { ReactComponent as AlertTriangleIcon } from "../../../assets/icons/alert-triangle.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as EllipsisIcon } from "../../../assets/icons/ellipsis.svg";

import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  selectDestinationTest,
  resetDestinationTest,
  testNewDestination,
  testExistingDestination,
} from "../../../store/destinations/destinations.duck";
import { ExistingDestinationPayload } from "../../../store/destinations";

type TestDestinationConnectionProps = {
  beforeSubmitTest?: () => boolean;
  preparedDestination?: PreparedDestination;
  existingDestination?: ExistingDestination | null;
};
const TestDestinationConnection = ({
  beforeSubmitTest = () => true,
  preparedDestination,
  existingDestination,
}: TestDestinationConnectionProps) => {
  const dispatch = useTypedDispatch();
  const destinationTest = useTypedSelector(selectDestinationTest);

  useEffect(() => {
    dispatch(resetDestinationTest());
  }, [preparedDestination]);

  // Cleanup test state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetDestinationTest());
    };
  }, []);

  const onTestConnection = () => {
    if (!beforeSubmitTest()) {
      return;
    }

    // If we have both, we're in the edit state of the DestinationForm and need to compute fields that changed
    if (
      existingDestination?.type === DestinationType.ExistingDestination &&
      preparedDestination?.type === DestinationType.PreparedDestination
    ) {
      const payload: ExistingDestinationPayload = {
        destinationId: existingDestination.id,
      };
      const changed = computeChangedFields(
        existingDestination,
        preparedDestination
      );
      if (Object.keys(changed).length > 0) {
        payload.fields = changed;
      }
      dispatch(testExistingDestination(payload));
    } else if (
      existingDestination?.type === DestinationType.ExistingDestination
    ) {
      dispatch(
        testExistingDestination({ destinationId: existingDestination.id })
      );
    } else if (preparedDestination) {
      dispatch(testNewDestination(preparedDestination));
    }
  };

  return (
    <div className="relative pt-4">
      <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-primary-500 focus-within:ring-1 focus-within:ring-primary-500">
        <div className="p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <div
                className={`mx-auto flex items-center justify-center h-10 w-10 rounded-full ${
                  destinationTest.status === "success"
                    ? "bg-emerald-100"
                    : destinationTest.status === "error"
                    ? "bg-red-100"
                    : destinationTest.status === "processing"
                    ? "bg-gray-100"
                    : "bg-gray-100"
                }`}
              >
                {destinationTest.status === "success" && (
                  <CheckIcon
                    className="h-5 w-5 text-emerald-600"
                    aria-hidden="true"
                  />
                )}
                {destinationTest.status === "error" && (
                  <AlertCircleIcon
                    className="h-5 w-5 text-red-600"
                    aria-hidden="true"
                  />
                )}
                {destinationTest.status === "processing" && (
                  <EllipsisIcon
                    className="h-5 w-5 text-gray-600"
                    aria-hidden="true"
                  />
                )}
                {!destinationTest.status && (
                  <AlertTriangleIcon
                    className="h-5 w-5 text-gray-600"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
            <div className="ml-3 mt-1">
              <h3 className="text-lg font-medium text-gray-800">
                {destinationTest.status === "success" &&
                  "Connection successful!"}
                {destinationTest.status === "error" &&
                  "Error connecting to destination."}
                {destinationTest.status === "processing" &&
                  "Testing connection..."}
                {!destinationTest.status &&
                  `Test connection${
                    preparedDestination ? " before saving." : " status."
                  } `}
              </h3>
              <div className="mt-2 mb-2 text-sm text-gray-700">
                <p>
                  {destinationTest.status === "success" &&
                    `Connection was successfully established to this destination.${
                      preparedDestination
                        ? " The destination details can now be saved."
                        : ""
                    }`}
                  {destinationTest.status === "error" && (
                    <>
                      <span className="block mb-4">
                        Try updating the destination details and try again.
                      </span>
                      <code className="text-xs">{destinationTest.message}</code>
                    </>
                  )}
                  {destinationTest.status === "processing" && ""}
                  {!destinationTest.status && ""}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div aria-hidden="true">
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-9" />
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 inset-x-px">
          <div className="border-t border-gray-200 px-2 py-2 flex justify-between items-center space-x-3 sm:px-3">
            <div className="flex">
              <div className="flex-shrink-0">
                <Button
                  onClick={onTestConnection}
                  type={ButtonStyle.PRIMARY}
                  disabled={destinationTest.status === "processing"}
                  text={
                    destinationTest.status === "processing" ? (
                      <div className="flex">
                        <Spinner.Inline className="mr-3 text-white" />
                        Testing Connection...
                      </div>
                    ) : (
                      "Test Connection"
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestDestinationConnection;
