import React, { useEffect } from "react";
import { CopyToClipboard, Spinner } from "@prequel-internal/react-components";

import { useTypedDispatch, useTypedSelector } from "../../../../../store";
import {
  clearSSHPublicKey,
  fetchSSHPublicKey,
  selectSSHPublicKey,
} from "../../../../../store/ssh/ssh.duck";

export enum PublicKeyAction {
  GENERATE_KEY,
  EXISTING_KEY,
}

type SSHPublicKeyProps =
  | {
      type: PublicKeyAction.GENERATE_KEY;
      setPublicKey: (publicKey: string) => void;
    }
  | {
      type: PublicKeyAction.EXISTING_KEY;
      existingPublicKey: string;
    };
const SSHPublicKey = (sshPublicKeyProps: SSHPublicKeyProps) => {
  const dispatch = useTypedDispatch();
  const publicKey = useTypedSelector(selectSSHPublicKey);

  useEffect(() => {
    if (sshPublicKeyProps.type === PublicKeyAction.GENERATE_KEY) {
      dispatch(fetchSSHPublicKey());
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearSSHPublicKey());
    };
  }, []);

  useEffect(() => {
    if (sshPublicKeyProps.type === PublicKeyAction.GENERATE_KEY && publicKey) {
      sshPublicKeyProps.setPublicKey(publicKey);
    }
  }, [publicKey]);

  const key =
    sshPublicKeyProps.type === PublicKeyAction.GENERATE_KEY
      ? publicKey
      : sshPublicKeyProps.existingPublicKey;

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">
        SSH Public Key
      </label>
      {key ? (
        <div className="w-full shadow-sm inline-flex rounded-md pl-3 pr-1 py-2 mt-1 text-gray-500 text-sm bg-gray-50 border border-gray-300 font-mono">
          <div className="w-full truncate mr-3">{key}</div>
          <CopyToClipboard textToCopy={key} />
        </div>
      ) : (
        <div className="flex text-sm mt-2 text-gray-500">
          <Spinner.Inline className="mr-3" /> {"Generating SSH key..."}
        </div>
      )}
      <p className="mt-2 text-xs text-gray-400">
        {`This is the public SSH key we'll use to establish the SSH tunnel.
           Please add it to the list of authorized keys on your bastion host `}
        <a
          href="https://docs.prequel.co/docs/ssh-tunneling"
          target="_blank"
          rel="noreferrer"
          className="font-medium text-primary-600 hover:text-primary-500"
        >
          (see documentation).
        </a>
      </p>
    </div>
  );
};

export default SSHPublicKey;
