import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { ToastInterface } from "@prequel-internal/react-components";
import { all, takeEvery, put, delay } from "redux-saga/effects";

import { RootState } from "..";
import { AppError } from "../../axios";

// Reducer Setup
type ToastsState = {
  toasts: ToastInterface[];
};
const initialState: ToastsState = { toasts: [] };

const createToastReducer: CaseReducer<
  ToastsState,
  PayloadAction<{ toast: ToastInterface }>
> = (state, action) => {
  state.toasts.push(action.payload.toast);
};

const dismissToastReducer: CaseReducer<
  ToastsState,
  PayloadAction<{ toastId: ToastInterface["id"] }>
> = (state, action) => {
  state.toasts = state.toasts.filter(
    (toast) => toast.id !== action.payload.toastId
  );
};

function* watchForErrors() {
  yield takeEvery("*", function* detectErrorSaga(action: PayloadAction<any>) {
    const appError: AppError = action?.payload;
    if (appError?.error && !appError.error.suppressGlobalNotification) {
      yield put(
        createToast({
          toast: {
            id: new Date().getTime().toString(),
            kind: "ERROR",
            message: appError?.error?.message,
          },
        })
      );
    }
  });
}

function* watchForAutoDismiss() {
  yield takeEvery(
    createToast.type,
    function* (action: PayloadAction<{ toast: ToastInterface }>) {
      const toast = action?.payload?.toast;
      if (!toast.autoDismissDisabled) {
        yield delay(5000);
        yield put(dismissToast({ toastId: toast.id }));
      }
    }
  );
}

export const toastsSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    createToast: createToastReducer,
    dismissToast: dismissToastReducer,
  },
});

const { createToast, dismissToast } = toastsSlice.actions;

const selectToasts = ({ toasts }: RootState) => toasts.toasts;

export { createToast, dismissToast, selectToasts };
export function* toastsSaga() {
  yield all([watchForErrors(), watchForAutoDismiss()]);
}
export default toastsSlice.reducer;
