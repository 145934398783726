import axios, { ApiRoutes, ApiResponse, ErrorResponse } from "../../axios";
import { WithRedirect } from "..";

import MagicLink, { FormMagicLink } from ".";
import { MagicLinkForm } from "../../lib";

const getMagicLinks: () => Promise<MagicLink[]> = () => {
  return axios
    .get(ApiRoutes.MAGIC_LINKS)
    .then(
      (response: ApiResponse<{ magic_links: MagicLink[] }>) =>
        response.data.data.magic_links
    )
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message || "Failed to fetch magic links.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postMagicLink: ({
  link,
  redirect,
}: WithRedirect<{
  link: FormMagicLink;
}>) => Promise<WithRedirect<{ link: MagicLink }>> = ({ link, redirect }) => {
  return axios
    .post(ApiRoutes.MAGIC_LINKS, { magic_link: link })
    .then((response: ApiResponse<{ magic_link: MagicLink }>) => ({
      link: response.data.data.magic_link,
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message || "Failed to post magic link.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const getMagicLinkForm: () => Promise<MagicLinkForm> = () => {
  return axios
    .get(ApiRoutes.PUBLIC_MAGIC_LINK_VENDORS)
    .then(
      (response: ApiResponse<{ magic_link_vendors: MagicLinkForm }>) =>
        response.data.data.magic_link_vendors
    )
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message ||
            "Failed to fetch magic link vendors.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const MagicLinksService = {
  getMagicLinks,
  postMagicLink,
  getMagicLinkForm,
};
export default MagicLinksService;
