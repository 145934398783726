import React from "react";
import { Destination, FormField } from "@prequel/react";
import {
  FormField as FormFieldComponent,
  PasswordDot,
} from "@prequel-internal/react-components";

type InputWrapperProps = {
  field: FormField & { form_element: "input" };
  destination: Destination;
  setDestinationField: (
    key: keyof Destination,
    value: string | boolean | string[]
  ) => void;
  disabled: boolean;
  isEditing: boolean;
};

const InputWrapper = ({
  field,
  destination,
  setDestinationField,
  disabled,
  isEditing,
}: InputWrapperProps) => {
  return (
    <FormFieldComponent
      label={field.label}
      id={field.name}
      type={field.input_type ?? "text"}
      subtext={field.description}
      placeholder={
        field.input_type === "password" && isEditing
          ? PasswordDot.repeat(20)
          : field.placeholder
      }
      overwriteOnly={field.input_type === "password" && isEditing}
      value={destination?.[field.name]?.toString() ?? ""}
      onChangeHandler={(value) => setDestinationField(field.name, value)}
      // If the field is required, make accomodations for editing
      // else the field is never required, return that value
      required={
        field.required
          ? (field.required && !isEditing) ||
            (isEditing && field.input_type !== "password")
          : field.required
      }
      disabled={disabled}
    />
  );
};

export default InputWrapper;
