import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, takeEvery } from "redux-saga/effects";
import { ExistingDestination } from "@prequel/react";

import { AppError } from "../../axios";
import { TransferReportDay } from ".";
import TransferReportService from "./transfers_report.service";
import { RootState, createWorkerSaga } from "..";

// Slice state
type TransferReportState = {
  transfers_report: TransferReportDay[] | undefined;
  isPostingTransferReportDay: boolean;
};
const initialState: TransferReportState = {
  transfers_report: undefined,
  isPostingTransferReportDay: false,
};

// Action Reducers (Case Reducers)
const fetchTransferReportReducer: CaseReducer<
  TransferReportState,
  PayloadAction<{ destinationId: ExistingDestination["id"] | undefined }>
> = (state) => state;

const fetchTransferReportSuccessReducer: CaseReducer<
  TransferReportState,
  PayloadAction<TransferReportDay[]>
> = (state, action) => {
  state.transfers_report = action.payload;
};

const fetchTransferReportFailureReducer: CaseReducer<
  TransferReportState,
  PayloadAction<AppError>
> = (state) => state;

function* watchFetchTransferReport() {
  yield takeEvery(
    fetchTransferReport.type,
    createWorkerSaga(
      fetchTransferReport,
      fetchTransferReportSuccess,
      fetchTransferReportFailure,
      TransferReportService.getTransferReport
    )
  );
}

const transfersReportSlice = createSlice({
  name: "transfers_report",
  initialState,
  reducers: {
    fetchTransferReport: fetchTransferReportReducer,
    fetchTransferReportSuccess: fetchTransferReportSuccessReducer,
    fetchTransferReportFailure: fetchTransferReportFailureReducer,
  },
});

export const {
  fetchTransferReport,
  fetchTransferReportSuccess,
  fetchTransferReportFailure,
} = transfersReportSlice.actions;

export const selectTransferReport = ({ transfers_report }: RootState) =>
  transfers_report.transfers_report;

export function* transfersReportSaga() {
  yield all([watchFetchTransferReport()]);
}
export default transfersReportSlice.reducer;
