import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import { all, takeEvery, put } from "redux-saga/effects";

import {
  RootState,
  createWorkerSaga,
  createRedirectSaga,
  WithRedirect,
} from "..";
import MagicLinksService from "./magic_links.service";
import MagicLink, { FormMagicLink } from ".";
import { AppError } from "../../axios";

import { createToast } from "../toasts/toasts.duck";
import { MagicLinkForm } from "../../lib";

// Slice state
type MagicLinksState = {
  links: MagicLink[] | undefined;
  form: MagicLinkForm | undefined;
};
const initialState: MagicLinksState = {
  links: undefined,
  form: undefined,
};

// Action Reducers (Case Reducers)
const fetchMagicLinksReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<void>
> = (state) => state;

const fetchMagicLinksSuccessReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<MagicLink[]>
> = (state, action) => {
  state.links = action.payload;
};

const fetchMagicLinksFailureReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<AppError>
> = (state) => state;

const createMagicLinkReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<WithRedirect<{ link: FormMagicLink }>>
> = (state) => state;

const createMagicLinkSuccessReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<WithRedirect<{ link: MagicLink }>>
> = (state) => state;

const createMagicLinkFailureReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<AppError>
> = (state) => state;

const fetchMagicLinkFormReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<void>
> = (state: MagicLinksState) => state;

const fetchMagicLinkFormSuccessReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<MagicLinkForm>
> = (state: MagicLinksState, action: PayloadAction<MagicLinkForm>) => {
  state.form = action.payload;
};

const fetchMagicLinkFormFailureReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<AppError>
> = (state: MagicLinksState) => state;

function* watchFetchMagicLinks() {
  yield takeEvery(
    fetchMagicLinks.type,
    createWorkerSaga(
      fetchMagicLinks,
      fetchMagicLinksSuccess,
      fetchMagicLinksFailure,
      MagicLinksService.getMagicLinks
    )
  );
}

function* watchCreateMagicLink() {
  yield takeEvery(
    createMagicLink.type,
    createWorkerSaga(
      createMagicLink,
      createMagicLinkSuccess,
      createMagicLinkFailure,
      MagicLinksService.postMagicLink
    )
  );
}

function* watchCreateMagicLinkSuccess() {
  yield takeEvery(createMagicLinkSuccess.type, function* () {
    yield put(fetchMagicLinks());
    yield put(
      createToast({
        toast: {
          id: new Date().getTime().toString(),
          kind: "SUCCESS",
          message: "Magic Link created successfully!",
        },
      })
    );
  });
  yield takeEvery(createMagicLinkSuccess.type, createRedirectSaga());
}

function* watchFetchMagicLinkForm() {
  yield takeEvery(
    fetchMagicLinkForm.type,
    createWorkerSaga(
      fetchMagicLinkForm,
      fetchMagicLinkFormSuccess,
      fetchMagicLinkFormFailure,
      MagicLinksService.getMagicLinkForm
    )
  );
}

export const magicLinksSlice = createSlice({
  name: "magic_links",
  initialState,
  reducers: {
    fetchMagicLinks: fetchMagicLinksReducer,
    fetchMagicLinksSuccess: fetchMagicLinksSuccessReducer,
    fetchMagicLinksFailure: fetchMagicLinksFailureReducer,
    createMagicLink: createMagicLinkReducer,
    createMagicLinkSuccess: createMagicLinkSuccessReducer,
    createMagicLinkFailure: createMagicLinkFailureReducer,
    fetchMagicLinkForm: fetchMagicLinkFormReducer,
    fetchMagicLinkFormSuccess: fetchMagicLinkFormSuccessReducer,
    fetchMagicLinkFormFailure: fetchMagicLinkFormFailureReducer,
  },
});

const {
  fetchMagicLinks,
  fetchMagicLinksSuccess,
  fetchMagicLinksFailure,
  createMagicLink,
  createMagicLinkSuccess,
  createMagicLinkFailure,
  fetchMagicLinkForm,
  fetchMagicLinkFormSuccess,
  fetchMagicLinkFormFailure,
} = magicLinksSlice.actions;

export const selectMagicLinks = ({ magic_links }: RootState) =>
  magic_links.links;
export const selectMagicLinkForm = ({ magic_links }: RootState) =>
  magic_links.form;

export {
  fetchMagicLinks,
  fetchMagicLinksSuccess,
  fetchMagicLinksFailure,
  createMagicLink,
  createMagicLinkSuccess,
  createMagicLinkFailure,
  fetchMagicLinkForm,
  fetchMagicLinkFormSuccess,
  fetchMagicLinkFormFailure,
};
export function* magicLinksSaga() {
  yield all([
    watchFetchMagicLinks(),
    watchCreateMagicLink(),
    watchCreateMagicLinkSuccess(),
    watchFetchMagicLinkForm(),
  ]);
}
export default magicLinksSlice.reducer;
