import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Alert, Card, Spinner } from "@prequel-internal/react-components";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchLoginToken,
  resetAuth,
  selectAuthorized,
} from "../../../store/auth/auth.duck";

const LoginSuccessfulCard = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const authorized = useTypedSelector(selectAuthorized);

  useEffect(() => {
    if (code !== null) {
      dispatch(fetchLoginToken(code));
    } else {
      navigate("/login");
    }
    // We can disable exhaustive deps here because we need this to run on mount ([] deps)
    // otherwise we get an error about an incorrect setState in the Authentication component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-center mt-36">
      <Card>
        {authorized?.state === "ERROR" ? (
          <Alert>{authorized.error}</Alert>
        ) : (
          <div className="text-xl flex justify-center items-center text-gray-700">
            <div className="mr-4">{"Logging you in... "}</div>
            <Spinner.Inline className="mr-3" />
          </div>
        )}
        <div className="text-sm flex justify-center text-gray-500">
          <div className="mr-1">Having trouble logging in?</div>
          <div
            className="text-primary-600 cursor-pointer hover:underline"
            onClick={() => {
              dispatch(resetAuth());
              navigate("/login");
            }}
          >
            Try again
          </div>
        </div>
      </Card>
    </div>
  );
};

export default LoginSuccessfulCard;
