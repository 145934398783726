import { ModelConfig, ProductConfig } from "@prequel/react";
import { RequestStatus } from "../../axios";

export const ALL_CURRENT_FUTURE_MODELS = ["*"];

export interface ExistingModel extends ModelConfig {
  id: string;
  created_at: string;
  updated_at: string;
}

export type ModelValidation = {
  status: RequestStatus;
  message?: string;
};

export const isAllCurrentFutureModels = (models: string[]) =>
  models.length === 1 && models[0] === ALL_CURRENT_FUTURE_MODELS[0];

export const getAvailableModels = (
  modelConfigs: ModelConfig[],
  selectedProducts: string[],
  productConfigs?: ProductConfig[]
) => {
  if (!productConfigs) {
    return modelConfigs;
  }
  let modelNames: string[] = [];
  productConfigs.map((cfg) => {
    // If the product is in the list, append all the models
    if (selectedProducts.includes(cfg.product_name)) {
      modelNames = [...modelNames, ...cfg.models];
    }
  });

  const models = modelConfigs.filter(({ model_name }) =>
    modelNames.includes(model_name)
  );

  return models;
};

export const getConfigsByModelNames = (
  configs: ModelConfig[],
  modelNames: string[]
) => {
  return configs.filter(({ model_name }) => modelNames.includes(model_name));
};
