import React from "react";
import { NavLink, useNavigate, useRoutes } from "react-router-dom";
import {
  Button,
  ButtonStyle,
  Heading,
  PageHeading,
  Tabs,
  checkTabIsActive,
} from "@prequel-internal/react-components";

import DestinationsTable from "./DestinationsTable";
import DestinationPage from "./DestinationPage";
import DestinationForm from "./DestinationForm";

const DestinationsPage = () => {
  const navigate = useNavigate();

  const tabs = [
    <NavLink key="Summary" to="#" className={checkTabIsActive}>
      Summary
    </NavLink>,
  ];

  const element = useRoutes([
    {
      path: "/",
      element: (
        <div className="max-w-6xl py-4 pb-16 px-7">
          <PageHeading title="Destinations">
            <Button
              text="Add Destination"
              onClick={() => navigate("new")}
              type={ButtonStyle.SECONDARY}
            />
          </PageHeading>
          <Tabs tabs={tabs} />
          <DestinationsTable />
        </div>
      ),
    },
    {
      path: "new",
      element: (
        <div className="max-w-2xl py-4 px-7">
          <PageHeading title="Add Destination" onGoBack={() => navigate(-1)} />
          <DestinationForm />
        </div>
      ),
    },
    {
      path: ":connectionId/edit",
      element: (
        <div className="max-w-2xl py-4 px-7">
          <PageHeading title="Edit Destination" onGoBack={() => navigate(-1)} />
          <DestinationForm />
        </div>
      ),
    },
    {
      path: ":connectionId/*",
      element: (
        <div className="max-w-4xl py-4 px-7">
          <DestinationPage />
        </div>
      ),
    },
  ]);

  return <div className="flex-col h-full">{element}</div>;
};

export default DestinationsPage;
