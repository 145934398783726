import axios, { ApiResponse, ApiRoutes, ErrorResponse } from "../../axios";
import ExistingProduct, { Product } from ".";
import { WithRedirect } from "..";

const getProducts: () => Promise<ExistingProduct[]> = () => {
  return axios
    .get(ApiRoutes.EXPORT_PRODUCTS)
    .then(
      (response: ApiResponse<{ products: ExistingProduct[] }>) =>
        response.data.data.products
    )
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message || "Failed to fetch products.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postProduct: ({
  product,
  redirect,
}: WithRedirect<{ product: Product }>) => Promise<
  WithRedirect<{ product: ExistingProduct }>
> = ({ product, redirect }) => {
  return axios
    .post(ApiRoutes.EXPORT_PRODUCTS, { product: product })
    .then((response: ApiResponse<{ product: ExistingProduct }>) => ({
      product: response.data.data.product,
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message: reason.response?.data?.message || "Failed to post product.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const ProductsService = {
  getProducts,
  postProduct,
};
export default ProductsService;
