import axios, { AxiosError } from "axios";
import { EnhancedStore } from "@reduxjs/toolkit";
import { env } from "../env";

export const ID_PLACEHOLDER = ":id";
export const API_VERSION_HEADER = "X-Prequel-Api-Version";
export const API_VERSION_2023_12_01 = "2023-12-01";
export const MAX_PAGE_SIZE = "100";

let reduxStore: EnhancedStore;
export const injectStore = (store: EnhancedStore) => {
  reduxStore = store;
};

const instance = axios.create({
  baseURL: env.REACT_APP_API_SERVER,
  withCredentials: true,
});

instance.defaults.headers.common["Content-Type"] = "application/json";

instance.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers["Environment"] = reduxStore.getState().auth.environment;
  }

  if (
    config.data?.destination &&
    typeof config.data === "object" &&
    !Array.isArray(config.data)
  ) {
    delete config.data.destination.type; // Delete the type field from destination
  }
  return config;
});

export enum ApiRoutes {
  AUTH_ENVIRONMENT = "/auth/environment",
  AUTH_STATUS = "/auth-status",
  AUTH_IAM_TRUST_POLICY = "/auth/iam-trust-policy",
  CANCEL_EXPORT_TRANSFER = "/export/transfers/:id/cancel",
  DESTINATIONS = "/destinations",
  GET_DESTINATION_DOCS = "/docs",
  GENERATE_PUBLIC_KEY = "/actions/generate-ssh-key",
  TRANSFERS = "/transfers",
  LOG_OUT = "/logout",
  MAGIC_LINKS = "/magic-links",
  EXPORT_MODELS = "/export/models",
  VALIDATE_EXPORT_MODEL = "/export/models/:id/validate",
  ORGS = "/orgs",
  EXPORT_PRODUCTS = "/export/products",
  PUBLIC_GOOGLE_OAUTH_LOGIN = "/public/auth/google-oauth-login",
  PUBLIC_PASSWORDLESS_LOGIN = "/public/auth/passwordless-login",
  PUBLIC_RETRIEVE_TOKEN = "/public/auth/retrieve-token",
  PUBLIC_SOURCE_VENDORS = "/public/vendors/sources",
  PUBLIC_SSO_ENABLED = "/public/auth/sso-enabled",
  PUBLIC_SSO_LOGIN = "/public/auth/sso-login",
  PUBLIC_MAGIC_LINK_VENDORS = "/public/vendors/magic-links",
  RECIPIENTS = "/recipients",
  RECIPIENT_SOURCE_CREDENTIALS = "/recipient-source-credentials",
  REPORT_TRANSFERS = "/reports/transfers",
  SOURCES = "/sources",
  TEST_DESTINATION = "/test-destination",
  TEST_EXISTING_DESTINATION = "/destinations/:id/test-connection",
  TEST_SOURCE = "/test-source",
  TEST_EXISTING_SOURCE = "/sources/:id/test-connection",
  TEST_RECIPIENT_SOURCE_CREDENTIALS = "/test-recipient-source-credential",
  TRANSFER = "/transfer",
  USER = "/me",
}

// ApiResponse and ErrorResponse are what we receive in then/catch clauses
export type ApiResponse<T> = {
  data: {
    status: string;
    data: T;
    message: string;
  };
};
export type ErrorResponse = AxiosError<{ message: string }, any>;

// AppError is the error type we pass around the application
export type AppError = {
  error: {
    message: string;
    suppressGlobalNotification?: boolean;
    statusCode?: number;
  };
};

export type RequestStatus = "processing" | "success" | "error" | undefined;

export default instance;
