import axios, {
  ApiResponse,
  ApiRoutes,
  AppError,
  ErrorResponse,
} from "../../axios";

const getCreateDestinationDocs: ({
  vendor,
}: {
  vendor: string;
}) => Promise<{ content: string; title: string }> = ({ vendor }) => {
  return axios
    .get(ApiRoutes.GET_DESTINATION_DOCS, { params: { vendor } })
    .then(
      (response: ApiResponse<{ content: string; title: string }>) =>
        response.data.data
    )
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message ||
            "Failed to get create destination docs.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const DocsService = {
  getCreateDestinationDocs,
};

export default DocsService;
